// External Imports
import React, { useState, useEffect } from "react";
// Config
import {
  getColorScheme,
  getMarkdownText,
} from "../../../exports/functions.exports";
import { IPricingCardData } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  PricingCardGridItem,
} from "../../../exports/components.exports";
import { Typography, Grid } from "@mui/material";

/**
 * @function SinglePriceCard
 * @returns the dynamic media card grid
 */
const SinglePriceCard = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, PriceCard, Paragraphs, Title } = blockData;
  const [formattedCardData, setFormattedCardData] = useState<
    Array<IPricingCardData>
  >([]);

  useEffect(() => {
    let tmpCardData: Array<IPricingCardData> = [];

    const getButton = (button: string) => {
      switch (button) {
        case "getStarted":
          return {
            text: "Get Started",
            link: urlRoutes.CRM_SIGNUP,
          };
        case "comingSoon":
          return {
            text: "Coming Soon",
            link: "",
          };
        case "contactUs":
          return {
            text: "Contact Us",
            link: urlRoutes.CONTACT,
          };

        default:
          return {
            text: "Get Started",
            link: urlRoutes.CRM_SIGNUP,
          };
      }
    };

    tmpCardData.push({
      title: PriceCard.Title,
      subtitle: PriceCard.Subtitle,
      price: PriceCard.Price,
      priceSubtitle: PriceCard.PriceNote,
      listItems: PriceCard.PriceInfo.split("//" || " // "),
      color: PriceCard.CardColor,
      notes: PriceCard.PriceNote,
      buttonText: getButton(PriceCard.Button).text,
      buttonLink: getButton(PriceCard.Button).link,
    });

    setFormattedCardData(tmpCardData);
  }, []);

  /**
   * @function getSimpleRepeatedTypography
   * @param paragraphs an array of strings
   * @returns a grid item and typography element for each string in the array, of body text, and secondary color scheme
   */
  const getSimpleRepeatedTypography = (paragraphs: Array<string>) => {
    return paragraphs.map((text: string, index: number) => {
      return (
        <Grid item xs={12} key={index}>
          <Typography
            className="dynamic-typography"
            variant="body1"
            sx={{ color: getColorScheme(ColorScheme)?.secondary }}
          >
            {text}
          </Typography>
        </Grid>
      );
    });
  };

  const rightContent = (cardData: IPricingCardData) => {
    return (
      <Grid
        container
        sx={{ justifyContent: "space-between" }}
        className="pricing-panel-one__grid-container"
        spacing={4}
      >
        {cardData && (
          <PricingCardGridItem mediumBreakpoint={12} cardData={cardData} />
        )}
      </Grid>
    );
  };

  const leftContent = () => {
    let paragraphs: Array<string | any> = [];
    Paragraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    return (
      <Grid container spacing={4} className="complex-two-column__left">
        {blockData?.Title && (
          <Grid item xs={12}>
            <Typography
              className="dynamic-typography"
              variant="h2"
              sx={{ color: getColorScheme(ColorScheme)?.primary }}
            >
              {Title}
            </Typography>
          </Grid>
        )}
        {blockData?.Paragraphs && getSimpleRepeatedTypography(paragraphs)}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      {formattedCardData && (
        <TwoColumnFlex
          placement="space-between"
          right={rightContent(formattedCardData[0])}
          left={leftContent()}
          leftPanelContentXPlacement="center"
          leftPanelContentYPlacement="top"
          rightPanelContentXPlacement="center"
          rightPanelContentYPlacement="top"
          noFixedHeight
        />
      )}
    </PanelWrapper>
  );
};

export default SinglePriceCard;
