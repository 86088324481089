// External Imports
import React from "react";
// Material UI
import { Grid, Paper } from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
// Functions
import { getHeader, getParagraphs } from "../../../exports/functions.exports";
// Types
import { ICareersAvailablePosition } from "../../../exports/types.exports";

/**
 * @function CareersPosition
 * @returns the pricing page
 */
const CareersPosition = ({
  jobTitle,
  jobSummary,
  responsibilities,
  qualifications,
  salary,
  benefits,
}: ICareersAvailablePosition) => {
  return (
    <Paper
      sx={{
        padding: "3rem",
        backgroundColor: colors.swiftcaseWebsiteLightGrey,
        marginBottom: "2rem",
      }}
      elevation={2}
    >
      <Grid
        container
        className="careers-page"
        spacing={4}
        sx={{ flexDirection: "column" }}
      >
        <Grid item xs={12}>
          {getHeader(jobTitle, colors.swiftcaseDarkBlue)}
        </Grid>
        <Grid item xs={12}>
          Summary:
          {getParagraphs([jobSummary], colors.swiftcaseMediumBlue)}
        </Grid>
        <Grid item xs={12}>
          Responsibilities:
          {getParagraphs([responsibilities], colors.swiftcaseMediumBlue)}
        </Grid>
        <Grid item xs={12}>
          Qualifications &amp; Skills:
          {getParagraphs([qualifications], colors.swiftcaseMediumBlue)}
        </Grid>
        <Grid item md={8} xs={12}>
          Salary:
          {getParagraphs([salary], colors.swiftcaseMediumBlue)}
        </Grid>
        <Grid item md={8} xs={12}>
          Benefits:
          {getParagraphs([benefits], colors.swiftcaseMediumBlue)}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CareersPosition;
