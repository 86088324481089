// External Imports
import React, { Fragment } from "react";
// Material UI
import { Paper, Typography, Button } from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";

const StickyFooter = () => {
  return (
    <Fragment>ß
      <Paper
        className="sticky-footer__container"
        elevation={24}
        square
        sx={{
          zIndex: 1000,
          position: "fixed",
          maxHeight: "15rem",
          bottom: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1.5rem",
          backgroundColor: colors.swiftcaseGreen,
          paddingY: "0.5rem",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h5"
          color={colors.swiftcaseWhite}
          sx={{
            maxWidth: "65%",
            textAlign: "center",
            display: { md: "block", xs: "none" },
          }}
        >
          Add a new income stream to your business with SwiftCase
        </Typography>
        <Typography
          variant="h5"
          color={colors.swiftcaseWhite}
          sx={{
            maxWidth: "75%",
            textAlign: "center",
            display: { md: "none", sm: "block", xs: "none" },
            paddingLeft: "8rem",
          }}
        >
          Partner with SwiftCase
        </Typography>
        <Button
          href={urlRoutes.PARTNER}
          sx={{
            fontSize: { md: "1.5rem", xs: "1.2rem" },
            textAlign: { md: "", xs: "center" },
            marginRight: { md: "", xs: "1rem" },
            color: colors.swiftcaseWhite,
            backgroundColor: colors.swiftcaseGreen,
            border: `0.075rem solid ${colors.swiftcaseWhite}`,
            display: { sm: "block", xs: "none" },
            ":hover": {
              backgroundColor: colors.swiftcaseGreen,
              color: colors.swiftcaseWhite,
              textDecoration: "underline",
              border: `0.075rem solid ${colors.swiftcaseWhite}`,
            },
          }}
          size="small"
          variant="outlined"
        >
          Learn More
        </Button>

        <Button
          href={urlRoutes.PARTNER}
          sx={{
            maxWidth: "50%",
            fontSize: { xs: "1.2rem" },
            textAlign: { xs: "center" },
            color: colors.swiftcaseWhite,
            backgroundColor: colors.swiftcaseGreen,
            border: `0.075rem solid ${colors.swiftcaseWhite}`,
            display: { sm: "none", xs: "block" },
            ":hover": {
              backgroundColor: colors.swiftcaseGreen,
              color: colors.swiftcaseWhite,
              textDecoration: "underline",
              border: `0.075rem solid ${colors.swiftcaseWhite}`,
            },
          }}
          size="small"
          variant="outlined"
        >
          Partner with us
        </Button>
      </Paper>
    </Fragment>
  );
};

export default StickyFooter;
