// External Imports
import React from "react";
// Components
import { DrawerSidebar } from "../../../exports/components.exports";
// Config
import { urlRoutes } from "../../../exports/constants.exports";
import { ILinkOptionProps } from "../../../exports/types.exports";

export const useGuideSidebar = (data: any) => {
  const allCategories = data.allStrapiCategory.edges;
  const allGuides = data.allGuides.edges;

  /**
   * @function renderOptions
   * @returns the list of links for the drawer sidebar
   */
  const renderNestedDrawerOptions = (data: any, baseUrl: string) => {
    let nestedDropdownObj: { [key: string]: Array<ILinkOptionProps> } = {};

    data.map((guideItem: any) => {
      let category = guideItem.node.Category.name;
      let newOption = {
        text: guideItem.node.Title,
        href: `${baseUrl}/article/${guideItem.node.Slug}`,
      };
      category in nestedDropdownObj
        ? nestedDropdownObj[category].push(newOption)
        : (nestedDropdownObj = {
            ...nestedDropdownObj,
            [category]: [newOption],
          });
    });

    return nestedDropdownObj;
  };

  /**
   * @function renderOptions
   * @returns the list of links for the drawer sidebar
   *
   * for the category level only
   */
  const renderDrawerOptions = (data: any, baseUrl: string) => {
    const dropdownOptions: Array<ILinkOptionProps> = [];
    data.map((dataItem: any) =>
      dropdownOptions.push({
        href: `${baseUrl}/${dataItem.node.slug}`,
        text: dataItem.node.name,
        disabled: dataItem.node.articles?.length > 0 ? true : false,
      })
    );

    return dropdownOptions;
  };

  return (
    <DrawerSidebar
      listItems={renderDrawerOptions(allCategories, urlRoutes.GUIDES)}
      nestedListData={renderNestedDrawerOptions(allGuides, urlRoutes.GUIDES)}
      nestedList
    />
  );
};
