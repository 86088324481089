// External Imports
import React, { Fragment } from "react";
// Material UI
import { Box, Typography } from "@mui/material";
// Config
import { ITextPanelTitleSubtitleProps } from "../../../exports/types.exports";
//Functions
import { getMarkdownText } from "../../../exports/functions.exports";

/**
 * @function TextPanelTitleSubtitle
 * @returns reusable component for a title and subtitle panel on useCases
 */
const TextPanelTitleSubtitle = ({
  title,
  subTitle,
  titleColor,
  subTitleColor,
}: ITextPanelTitleSubtitleProps) => {
  let formattedSubtitle = getMarkdownText(subTitle);

  return (
    <Fragment>
      <Box
        className="text-title-subtitle-panel"
        sx={{ position: "relative", maxWidth: { md: "90%", xs: "100%" } }}
      >
        <Typography
          color={titleColor}
          className="text-title-subtitle-panel__title"
          variant="h2"
          textAlign="left"
          sx={{ marginBottom: "4rem" }}
        >
          {title}
        </Typography>
        <Typography
          color={subTitleColor}
          className="text-title-subtitle-panel__subtitle"
          variant="body1"
          textAlign="left"
        >
          {formattedSubtitle}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default TextPanelTitleSubtitle;
