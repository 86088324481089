// External Imports
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// Config
import { ISeoData } from "../../../exports/types.exports";

/**
 * @function SEO
 * @returns the seo and React Helmet for the site, dynamic html head for each page
 */
const SEO = ({ seo }: { seo: ISeoData }) => {
  const { strapiGlobal } = useStaticQuery(query);
  const { favicon } = strapiGlobal;

  const getMetaTags = () => {
    const tags = [];
    let title = seo.metaTitle;
    let description = seo.metaDescription;
    

    tags.push(
      {
        name: "title",
        property: "title",
        content: title,
      },
      {
        name: "og:title",
        property: "og:title",
        content: title,
      },
      {
        name: "twitter:title",
        property: "twitter:title",
        content: title,
      },
      {
        name: "description",
        property: "description",
        content: description,
      },
      {
        name: "og:description",
        property: "og:description",
        content: description,
      },
      {
        name: "twitter:description",
        property: "twitter:description",
        content: description,
      }
    );

    var imageUrl = (process.env.GATSBY_ROOT_URL || "http://localhost:8000") + '/swiftcase-logo-47f35d0e65b4d4265ea77b8b31b8e18d.png';

    if (seo.shareImage) {
      if (seo.shareImage.localFile.childImageSharp) {
        const fallbackSrc = seo.shareImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src;
        imageUrl =
          (process.env.GATSBY_ROOT_URL || "http://localhost:8000") + fallbackSrc;
      } else if (seo.shareImage.localFile.publicURL) {
        const { publicURL } = seo.shareImage.localFile;
        imageUrl =
          (process.env.GATSBY_ROOT_URL || "http://localhost:8000") + publicURL;
      }
    }

    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    );

    if (seo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push(
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "@swiftcaseUK" },
      { name: "og:url", content: process.env.GATSBY_ROOT_URL },
      { name: "og:type", content: "website" },
      { name: "og:locale", content: "en_GB" }
    );

    return tags;
  };

  const getLinkTags = () => {
    const tags = [];

    tags.push(
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com/",
        crossOrigin: "true",
      },
      {
        rel: "icon",
        href: favicon.publicURL,
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0",
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined",
      },
    );

    return tags;
  };

  const getScriptTags = () => {
    let tags = [];

    tags.push({
      type: "text/javascript",
      id: "cookieyes",
      src: "https://cdn-cookieyes.com/client_data/e600cec88cbaa6f373816731/script.js",
    });

    return tags;
  };

  const metaTags = getMetaTags();

  const linkTags = getLinkTags();

  const scriptTags = getScriptTags();

  return (
    seo && (
      <Helmet defer={false} htmlAttributes={{ lang: "en" }}>
        {metaTags.map((metaTag, index) => (
          <meta key={index} {...metaTag} />
        ))}
        <title>{seo.metaTitle}</title>
        {linkTags.map((linkTag, index) => (
          <link key={index} {...linkTag} />
        ))}
        {scriptTags.map((scriptTag, index) => (
          <script key={index} {...scriptTag} />
        ))}
      </Helmet>
    )
  );
};

export default SEO;

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
