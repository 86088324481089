// External Imports
import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
// Assets
import { SwiftcaseLogo } from "../../../exports/assets.exports";
// Material UI
import { Link, Box } from "@mui/material";
import { DrawerSidebar } from "../../../exports/components.exports";
// Constants
import { urlRoutes } from "../../../exports/constants.exports";
// Config
import {
  ILinkOptionProps,
  IDropdownLink,
} from "../../../exports/types.exports";

/**
 * Query to get the Data from strapiNavbar
 */
const query = graphql`
  query {
    strapiNavbar {
      Menu
    }
  }
`;

/**
 * @function useMobileNavbar -> a custom hook for generating the mobile navbar
 * @returns the functions for generating the mobile view of the navbar
 */
export const useMobileNavbar = () => {
  const {
    strapiNavbar: { Menu },
  } = useStaticQuery(query);

  /**
   * @function mobileNavLogo
   * @returns the logo for Mobile View
   */
  const mobileNavLogo = () => {
    return (
      <Fragment>
        <Box
          className="mobile-navbar__logo-wrapper"
          sx={{
            mr: 2,
            flexGrow: 1,
            display: { xs: "flex", lg: "none" },
            maxHeight: "10rem",
            maxWidth: "10rem",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Link
            href={urlRoutes.HOMEPAGE}
            underline="none"
            className="mobile-navbar__logo-link"
          >
            <img
              src={SwiftcaseLogo}
              alt="SwiftCase Logo"
              loading="eager"
              style={{
                padding: 0,
                marginTop: "0.5rem",
                width: "100%",
                height: "100%",
              }}
              className="mobile-navbar__logo"
            />
          </Link>
        </Box>
      </Fragment>
    );
  };

  /**
   * @function renderListItems
   * @returns the list items for the drawer, the first level list
   */
  const renderListItems = () => {
    let listItems: Array<ILinkOptionProps> = [];
    let navbarLinkAndDropdown = Menu.filter(
      (item: any) =>
        item.strapi_component === "shared.link" ||
        item.strapi_component === "shared.dropdown"
    );

    navbarLinkAndDropdown.map((linkOrDropdown: any) => {
      if (linkOrDropdown.strapi_component === "shared.link") {
        listItems.push({
          text: linkOrDropdown.LinkText,
          href: linkOrDropdown.LinkHref,
        });
      } else {
        listItems.push({
          text: linkOrDropdown.DropdownTitle,
          href: "",
          disabled: true,
        });
      }
    });

    return listItems;
  };

  /**
   * @function renderNestedListItems
   * @returns the nested list items, for the navbar dropdowns
   */
  const renderNestedListItems = () => {
    let nestedDropdownObj: { [key: string]: Array<ILinkOptionProps> } = {};
    const navbarDropdown = Menu.filter(
      (item: any) => item.strapi_component === "shared.dropdown"
    );

    navbarDropdown.map((dropdown: any) => {
      const { DropdownTitle, Link } = dropdown;
      let DropdownOptions: Array<IDropdownLink> = [];
      Link.forEach((link: any) =>
        DropdownOptions.push({ text: link.LinkText, href: link.LinkHref })
      );

      nestedDropdownObj = {
        ...nestedDropdownObj,
        [DropdownTitle]: DropdownOptions,
      };
    });

    return nestedDropdownObj;
  };

  /**
   * @function mobileNavMenu
   * @returns the menu options for Mobile View
   */
  const mobileNavMenu = () => {
    return (
      <Fragment>
        <Box
          sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
          className="mobile-navbar"
        >
          <DrawerSidebar
            alternateMobileButton
            listItems={renderListItems()}
            nestedList
            nestedListData={renderNestedListItems()}
          />
        </Box>
      </Fragment>
    );
  };

  return { mobileNavMenu, mobileNavLogo };
};
