// External Imports
import React from "react";
import { Grid, Typography } from "@mui/material";
// Config
import {
  getColorScheme,
  getImageSize,
  getMaterialButton,
  getMarkdownText,
} from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  PaddedImage,
  BorderedTextParagraph,
} from "../../../exports/components.exports";

/**
 * @function ComplexTwoColumn
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const ComplexTwoColumn = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, ReverseColumnLayout, LeftImageSize, RightImageSize } =
    blockData;

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getImageContent
   * @param image to be rendered
   * @returns the image inside a padded image component
   */
  const getImageContent = (image: any, imageSize: string) => {
    return (
      <PaddedImage
        noBorder={0}
        noShadow="none"
        image={image.url}
        backgroundColor="transparent"
        maxWidth={getImageSize(imageSize)}
      />
    );
  };

  /**
   * @function getButtonPosition
   * @param position string position with value right|centre|left
   * @returns the justify content value for placement
   */
  const getButtonPosition = (position: string) => {
    switch (position) {
      case "right":
        return "flex-end";
      case "center":
        return "center";
      case "left":
        return "flex-start";
      default:
        return "flex-start";
    }
  };

  /**
   * @function getBorderedText
   * @param paragraphs an array of strings
   * @returns the bordered text component
   */
  const getBorderedText = (paragraphs: Array<string>) => {
    return (
      <Grid item xs={12}>
        <BorderedTextParagraph
          borderColor={getColorScheme(ColorScheme)?.primary}
          border="left"
          paragraphs={paragraphs}
          fontColor={getColorScheme(ColorScheme)?.secondary}
        />
      </Grid>
    );
  };

  /**
   * @function getSimpleRepeatedTypography
   * @param paragraphs an array of strings
   * @returns a grid item and typography element for each string in the array, of body text, and secondary color scheme
   */
  const getSimpleRepeatedTypography = (paragraphs: Array<string>) => {
    return paragraphs.map((text: string, index: number) => {
      return (
        <Grid item xs={12} key={index}>
          {getTypography("body1", text, getColorScheme(ColorScheme)?.secondary)}
        </Grid>
      );
    });
  };

  const rightContent = () => {
    let paragraphs: Array<string | any> = [];
    blockData?.RightParagraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    return (
      <Grid
        container
        spacing={4}
        className="complex-two-column__right"
        sx={{ alignSelf: blockData?.RightButton ? "stretch" : "" }} // to stick the button to the bottom of the grid
      >
        {blockData?.RightTitle && (
          <Grid item xs={12}>
            {getTypography(
              "h2",
              blockData?.RightTitle,
              getColorScheme(ColorScheme)?.primary
            )}
          </Grid>
        )}
        {/* If RightParagraphs and RightColumnParagraphBorderedText === true return the bordered text element else return simple Typography components */}
        {blockData?.RightParagraphs &&
        blockData?.RightColumnParagraphBorderedText
          ? getBorderedText(paragraphs)
          : getSimpleRepeatedTypography(paragraphs)}
        {blockData?.RightImage && (
          <Grid
            item
            xs={12}
            sx={{
              ".padded-image__wrapper": {
                marginX: "auto",
              },
            }}
          >
            {getImageContent(blockData?.RightImage, RightImageSize)}
          </Grid>
        )}
        {blockData?.RightButton && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: getButtonPosition(
                blockData?.RightButtonHorizontalPosition
              ),
            }}
          >
            {getMaterialButton(blockData?.RightButton)}
          </Grid>
        )}
      </Grid>
    );
  };

  const leftContent = () => {
    let paragraphs: Array<string | any> = [];
    blockData?.LeftParagraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    return (
      <Grid
        container
        spacing={4}
        className="complex-two-column__left"
        sx={{ alignSelf: blockData?.LeftButton ? "stretch" : "" }} // to stick the button to the bottom of the grid
      >
        {blockData?.LeftTitle && (
          <Grid item xs={12}>
            {getTypography(
              "h2",
              blockData?.LeftTitle,
              getColorScheme(ColorScheme)?.primary
            )}
          </Grid>
        )}
        {blockData?.LeftParagraphs && blockData?.LeftColumnParagraphBorderedText
          ? getBorderedText(paragraphs)
          : getSimpleRepeatedTypography(paragraphs)}
        {blockData?.LeftImage && (
          <Grid
            item
            xs={12}
            sx={{
              ".padded-image__wrapper": {
                marginX: "auto",
              },
            }}
          >
            {getImageContent(blockData?.LeftImage, LeftImageSize)}
          </Grid>
        )}
        {blockData?.LeftButton && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: getButtonPosition(
                blockData?.LeftButtonHorizontalPosition
              ),
            }}
          >
            {getMaterialButton(blockData?.LeftButton)}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default ComplexTwoColumn;
