const getPanelConentPlacement = (placement: string) => {
  switch (placement) {
    case "top":
      return "flex-start";
    case "left":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "right":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "center";
  }
};

export default getPanelConentPlacement;
