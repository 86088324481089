// External Imports
import React from "react";
// Config
import { colors, theme } from "../../../exports/assets.exports";
// Components
import {
  useScrollTrigger,
  Typography,
  Divider,
  ListItem,
  List,
  Paper,
  ListItemButton,
} from "@mui/material";

/**
 * @function ListOfContentsComponent component
 * @param contentList an array of strings of all of the headers (headerText), uses Scrollspy to navigate to them
 * @returns the list of contents which are an internal href to a hash value, scrolls the page
 */
const ListOfContentsComponent = ({
  contentList,
}: {
  contentList: Array<string>;
}) => {
  const trigger = useScrollTrigger({
    threshold: 400,
    disableHysteresis: true,
  });

  /**
   * uses the hash from the url, this is added when clicking on the list
   */
  const selectedHash =
    typeof window !== "undefined"
      ? window.location.hash.replace(/%20/g, " ").replace("#", "")
      : "";

  const getListOfContents = () => {
    return contentList.map((item: any, index: number) => {
      return (
        <ListItem divider disableGutters key={index} dense>
          <ListItemButton
            sx={{
              color:
                selectedHash === item
                  ? colors.swiftcaseWhite
                  : colors.swiftcaseDarkBlue,
              fontWeight: theme.fontWeights.swiftcaseMidweight,
              backgroundColor:
                selectedHash === item
                  ? colors.swiftcaseLightestBlue
                  : "initial",
              ":hover": {
                color: colors.swiftcaseDarkBlue,
                backgroundColor: colors.swiftcaseLightBlueOpaque,
              },
            }}
            href={`#${item}`}
          >
            {item}
          </ListItemButton>
        </ListItem>
      );
    });
  };

  return (
    <Paper
      sx={{
        display: { xs: "none", lg: "block" },
        top: trigger ? "10rem" : "12rem",
        right: "5rem",
        position: trigger ? "fixed" : "absolute",
        maxWidth: { xl: "32rem", lg: "25rem" },
        maxHeight: "75vh",
        overflowY: "auto",
        zIndex: 20,
      }}
      elevation={4}
      className="list-of-contents-container"
    >
      <Typography variant="h4" sx={{ paddingX: "1.6rem", paddingY: "1.5rem" }}>
        In This Guide
      </Typography>
      <Divider />
      <List>{getListOfContents()}</List>
    </Paper>
  );
};

export default ListOfContentsComponent;
