// External Imports
import React from "react";
import { Button } from "@mui/material";
// Config
import { getButtonColor } from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";

/**
 * @function getMaterialButton
 * @param button the button data from strapi
 * @returns the button from material
 */
const getMaterialButton = (
  button: any,
  size?: "small" | "medium" | "large"
) => {
  if (!button) return undefined;

  const { ButtonText, ButtonColor, ButtonLink, ButtonVariant, PageLink } =
    button;
  let buttonHref =
    PageLink && PageLink !== null ? `/${PageLink.slug}` : ButtonLink;

  return (
    <Button
      variant={ButtonVariant}
      size={size ?? "large"}
      href={buttonHref}
      className="dynamic-button"
      sx={{
        backgroundColor: getButtonColor(ButtonColor),
        color:
          ButtonColor === "offWhite"
            ? colors.swiftcaseDarkBlue
            : colors.swiftcaseWhite,
        ":hover": {
          backgroundColor: getButtonColor(ButtonColor),
          color:
            ButtonColor === "offWhite"
              ? colors.swiftcaseDarkBlue
              : colors.swiftcaseWhite,
          textDecoration: "underline",
        },
      }}
    >
      {ButtonText}
    </Button>
  );
};

export default getMaterialButton;
