// External Imports
import React, { Fragment } from "react";
// Material UI
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import {
  IInfoCard,
  ISinglePanelLayoutProps,
} from "../../../exports/types.exports";

/**
 * @function SinglePanelLayout
 * @returns the partners panel two component
 */
const SinglePanelLayout = ({
  panelDescription,
  title,
  complexTitle,
  subTitle,
  footer,
  cardBodyContent,
  primaryTextColor,
  secondaryTextColor,
  centrePanelDescription,
  pageHeader,
  panelDescriptionCasing,
  justifyFooter,
  singleBodyContent,
  singleBodyContentPosition,
}: ISinglePanelLayoutProps) => {
  /**
   * @function renderGridItemCard
   * @return the grid item and card content for the help to grow info text
   */
  const renderGridItemCard = () => {
    return cardBodyContent?.map((cardInfo: IInfoCard, index: number) => {
      return (
        <Grid
          key={index}
          item
          sx={{ marginX: "auto", marginY: { md: 0, xs: "2rem" } }}
          lg={3.5}
          md={5.5}
          xs={12}
          className="single-panel-layout__card-grid-item"
        >
          <Card
            className="single-panel-layout__card"
            variant="elevation"
            sx={{
              maxWidth: { md: "48rem", xs: "35rem" },
              height: "100%",
              marginX: "auto",
            }}
          >
            <CardContent className="single-panel-layout__card-content">
              <Typography
                className="single-panel-layout__card-title"
                color={colors.swiftcaseDarkBlue}
                variant="h3"
                textAlign="center"
                sx={{ marginBottom: "2rem" }}
              >
                {cardInfo.title}
              </Typography>
              <Typography
                className="single-panel-layout__card-subtitle"
                color={colors.swiftcaseMidGrey}
                variant="body1"
                textAlign="center"
              >
                {cardInfo.subtitle}
              </Typography>
            </CardContent>
            {cardInfo.cardFooter && (
              <CardActionArea
                className="single-panel-layout__card-action"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingX: "2rem",
                  width: "100%",
                  marginY: "2rem",
                }}
              >
                {cardInfo.cardFooter}
              </CardActionArea>
            )}
          </Card>
        </Grid>
      );
    });
  };

  const getSingleBodyContentPosition = () => {
    switch (singleBodyContentPosition) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4} className="single-panel-layout">
        {panelDescription && (
          <Grid item xs={12} className="single-panel-layout__grid-item">
            <Typography
              color={
                secondaryTextColor ??
                primaryTextColor ??
                colors.swiftcaseDarkBlue
              }
              className="single-panel-layout__header"
              variant="body1"
              textAlign={centrePanelDescription ?? "left"}
              sx={{
                padding: 0,
                textTransform: panelDescriptionCasing ?? "uppercase",
              }}
            >
              {panelDescription}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} className="single-panel-layout__grid-item">
          {title ? (
            <Typography
              color={primaryTextColor ?? colors.swiftcaseDarkBlue}
              className="single-panel-layout__header"
              variant={pageHeader ?? "h2"}
              textAlign="center"
              sx={{ padding: 0 }}
            >
              {title}
            </Typography>
          ) : (
            complexTitle
          )}
        </Grid>
        {subTitle && (
          <Grid item xs={12} className="single-panel-layout__grid-item">
            <Typography
              className="single-panel-layout__card-title"
              color={
                secondaryTextColor ??
                primaryTextColor ??
                colors.swiftcaseDarkBlue
              }
              textAlign="center"
              variant="body1"
            >
              {subTitle}
            </Typography>
          </Grid>
        )}
        {cardBodyContent && (
          <Grid item xs={12} className="single-panel-layout__grid-item">
            <Grid
              container
              className="single-panel-layout__card-container"
              sx={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {renderGridItemCard()}
            </Grid>
          </Grid>
        )}
        {singleBodyContent && (
          <Grid item xs={12} className="single-panel-layout__grid-item">
            <Grid
              container
              className="single-panel-layout__card-container"
              sx={{
                width: "100%",
                justifyContent: singleBodyContentPosition
                  ? getSingleBodyContentPosition()
                  : "center",
              }}
            >
              {singleBodyContent}
            </Grid>
          </Grid>
        )}
        {footer && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: justifyFooter ?? "center",
              marginTop: { md: "2rem", xs: "0" },
            }}
            className="single-panel-layout__grid-item"
          >
            {footer}
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default SinglePanelLayout;
