// External Imports
import React, { useState, Fragment } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Stack,
  Paper,
} from "@mui/material";
import { Download } from "@mui/icons-material";
// Config
import {
  createTask,
  getColorScheme,
  getImageSize,
  getMarkdownText,
} from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";
import { IResponse, ITaskKeyValuePair } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  SnackbarFeedback,
  PaddedImage,
} from "../../../exports/components.exports";

/**
 * @function EmailCaptureFileDownload
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const EmailCaptureFileDownload = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    Title,
    Subtitle,
    IncludeFirstName,
    IncludeLastName,
    SwiftcaseFileTag,
    File,
    ReverseColumnLayout,
    Image,
    ImageSize,
  } = blockData;
  const [isSubmitEmailSuccess, setIsSubmitEmailSuccess] = useState<
    true | false | "error"
  >(false);

  const rightContent = () => {
    return (
      <PaddedImage
        image={Image.url}
        maxWidth={getImageSize(ImageSize)}
        noBorder={0}
        noShadow="none"
        backgroundColor="transparent"
      />
    );
  };

  let formattedSubtitle = getMarkdownText(Subtitle);

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "h4" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  const EmailCaptureForm = ({
    fileTagName,
    includeFirstName,
    includeSurname,
    callBack,
  }: {
    fileTagName: string;
    includeFirstName: boolean;
    includeSurname: boolean;
    callBack: (result: true | false | "error") => void;
  }) => {
    const [firstName, setFirstName] = useState("");
    const [surName, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
    const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
    const [isSubmissionError, setIsSubmissionError] = useState(false);
    const [formHasError, setFormHasError] = useState(false);

    /**
     * resets state of form fields
     */
    const resetForm = () => {
      setFirstName("");
      setSurname("");
      setEmail("");
    };

    /**
     * @function getFormFields
     * @returns List of fields tied to controls to manage the form conents
     */
    const getFormFields = () => {
      return (
        <Fragment>
          {includeFirstName && (
            <TextField
              onKeyUp={validateForm}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              label="First Name"
              required
              sx={{
                margin: "1rem 0",
                borderColor: colors.swiftcaseBlue,
              }}
            />
          )}
          {includeSurname && (
            <TextField
              onKeyUp={validateForm}
              value={surName}
              onChange={(e) => setSurname(e.target.value)}
              placeholder="Last Name"
              label="Last Name"
              required
              sx={{
                margin: "1rem 0",
                borderColor: colors.swiftcaseBlue,
              }}
            />
          )}
          <TextField
            onKeyUp={validateForm}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            label="Email"
            required
            sx={{ margin: "1rem 0", backgroundColor: colors.swiftcaseOffwhite }}
          />
        </Fragment>
      );
    };

    /**
     * @function getButton
     * @returns Relevant button based on form submission state
     */
    const getButton = () => {
      return isSubmissionSuccess ? (
        <Button
          disabled
          variant="contained"
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
            backgroundColor: colors.swiftcaseGreen,
            ":hover": {
              backgroundColor: colors.swiftcaseGreen,
              textDecoration: "underline",
            },
          }}
        >
          Success
        </Button>
      ) : isProcessingSubmission ? (
        <Button
          disabled
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
          }}
        >
          <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
        </Button>
      ) : (
        <Button
          onClick={performContactRequest}
          variant="contained"
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
            backgroundColor: !formHasError
              ? colors.swiftcaseMediumBlue
              : colors.swiftcaseLightPink,
            ":hover": {
              textDecoration: "underline",
              backgroundColor: !formHasError
                ? colors.swiftcaseMediumBlue
                : colors.swiftcaseLightPink,
            },
          }}
        >
          {!formHasError ? "Download" : "Please fill all fields"}
        </Button>
      );
    };

    const validateForm = () => {
      let hasError = false;

      if (email !== "") {
        if (formHasError) {
          setFormHasError(hasError);
        }
      } else if (
        (includeFirstName && firstName !== "") ||
        (includeSurname && surName !== "")
      ) {
        if (formHasError) {
          setFormHasError(hasError);
        }
      } else {
        hasError = true;
        setFormHasError(hasError);
      }

      return hasError;
    };

    /**
     * Builds request body and calls service method "Create Task" to create Contact Us task, handles redirect
     */
    const performContactRequest = async () => {
      if (!validateForm()) {
        let response: IResponse = { isAccepted: false, message: "" };
        setIsProcessingSubmission(true);

        let taskData: Array<ITaskKeyValuePair> = [
          {
            name: "client_email_address",
            value: `${email}`,
          },
          {
            name: "file_tag_name",
            value: `${fileTagName}`,
          },
        ];

        if (includeFirstName) {
          taskData.push({
            name: "first_name",
            value: `${firstName}`,
          });
        }
        if (includeSurname) {
          taskData.push({
            name: "surname",
            value: `${surName}`,
          });
        }

        response = await createTask(
          process.env.REACT_APP_WEBSITE_LEAD_MAGNET_WORKFLOW_ID!,
          taskData,
          response
        );

        setIsProcessingSubmission(false);
        resetForm();

        if (response.isAccepted) {
          setIsSubmissionSuccess(true);
          callBack(true);
        } else {
          setIsSubmissionError(true);
          callBack("error");
        }
      }
    };

    return (
      <Grid item xs={12}>
        <form className="swiftcase-contact-form">
          {getFormFields()}
          {getButton()}
        </form>
        {isSubmissionSuccess && (
          <SnackbarFeedback
            alertType="success"
            alertMessage="Thank you. Your details have been successfully sent."
          />
        )}
        {isSubmissionError && (
          <SnackbarFeedback
            alertType="error"
            alertMessage="We're sorry. There was a problem submitting your request, please try again."
          />
        )}
      </Grid>
    );
  };

  const DownloadButton = ({ file }: { file: any }) => {
    const { name, url } = file;
    const handleDownload = (fileUrl: string, fileName: string) => {
      let downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.setAttribute("download", fileName);
      downloadLink.click();
      downloadLink.remove();
    };

    const fetchFileDownload = (fileUrl: string, fileName: string) => {
      fetch(url, {
        headers: {
          Origin: window.location.origin,
          "Content-Disposition": "attachment",
        },
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          handleDownload(blobUrl, fileName);
        })
        .catch((e) => console.error(e));
    };

    return (
      <Button
        onClick={() => fetchFileDownload(url, name)}
        variant="contained"
        startIcon={<Download />}
        sx={{
          margin: "1rem 0",
          backgroundColor: colors.swiftcaseGreen,
          ":hover": {
            textDecoration: "underline",
            backgroundColor: colors.swiftcaseGreen,
          },
        }}
      >
        Download File
      </Button>
    );
  };

  const handleCallback = (result: true | false | "error") => {
    setIsSubmitEmailSuccess(result);
  };

  const leftContent = () => {
    return (
      <Grid container spacing={4} className="complex-two-column__left">
        {blockData?.Title && (
          <Grid item xs={12}>
            {getTypography("h2", Title, getColorScheme(ColorScheme)?.primary)}
          </Grid>
        )}
        {blockData?.Subtitle && (
          <Grid item xs={12}>
            <Typography
              color={getColorScheme(ColorScheme)?.primary}
              className="dynamic-typography"
              variant="body1"
            >
              {formattedSubtitle}
            </Typography>
          </Grid>
        )}
        {isSubmitEmailSuccess === false && (
          <EmailCaptureForm
            fileTagName={SwiftcaseFileTag}
            includeFirstName={IncludeFirstName}
            includeSurname={IncludeLastName}
            callBack={handleCallback}
          />
        )}
        {isSubmitEmailSuccess === true && (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                padding: "2rem",
                backgroundColor: getColorScheme(ColorScheme)?.background,
              }}
            >
              <Stack spacing={4} direction="column" alignItems="flex-start">
                {getTypography(
                  "h4",
                  `Thank you for submitting your email address, you can now download the ${
                    File.name.split(".")[0]
                  } file below.`,
                  getColorScheme(ColorScheme)?.primary
                )}
                <DownloadButton file={File} />
              </Stack>
            </Paper>
          </Grid>
        )}
        {isSubmitEmailSuccess === "error" && (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                padding: "2rem",
                backgroundColor: getColorScheme(ColorScheme)?.background,
              }}
            >
              <Stack spacing={4} direction="column" alignItems="flex-start">
                {getTypography(
                  "h4",
                  "Something went wrong with submitting you're email address, please try again, or contact us here.",
                  getColorScheme(ColorScheme)?.primary
                )}
                <Button
                  href={urlRoutes.CONTACT}
                  variant="contained"
                  sx={{
                    backgroundColor: colors.swiftcaseWebsitePink,
                    ":hover": {
                      textDecoration: "underline",
                      backgroundColor: colors.swiftcaseWebsitePink,
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Stack>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement={ReverseColumnLayout ? "center" : "top"}
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement={ReverseColumnLayout ? "top" : "center"}
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default EmailCaptureFileDownload;
