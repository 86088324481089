/**
 * @function getImageSize
 * @param imageSize the size of the image; small, medium, large
 * @returns the image size in rem
 */
const getImageSize = (imageSize: string) => {
  switch (imageSize) {
    case "small":
      return "50%";
    case "medium":
      return "75%";
    case "large":
      return "90%";

    default:
      return "70%";
  }
};

export default getImageSize;
