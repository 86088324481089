// External Imports
import React, { Fragment } from "react";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { getMarkdownText } from "../../../exports/functions.exports";
// Components
import { PaddedImage } from "../../../exports/components.exports";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Grid,
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItemAvatar,
  Paper,
} from "@mui/material";

interface IListData {
  MarkdownText?: string;
  Media?: any;
  Text?: string;
}

/**
 * @function ListElement component
 * @params listData from strapi component for either the bullet point list or numbered list
 * @returns either a bullet pointed list or a numbered list within a grid item
 */
const ListElement = ({
  listData,
  bulletPoint = false,
}: {
  listData: Array<IListData>;
  bulletPoint: boolean;
}) => {
  const getListItems = (listData: Array<IListData>) => {
    return listData.map(({ MarkdownText, Media, Text }, index: number) => {
      return (
        <Fragment key={index}>
          <ListItem dense key={index}>
            {!bulletPoint && (
              <ListItemAvatar
                sx={{ alignSelf: "flex-start", paddingTop: "0.5rem" }}
              >
                <Avatar
                  className="dynamic-guide__number-list-icon"
                  sx={{
                    width: "2.75rem",
                    height: "2.75rem",
                    backgroundColor: colors.swiftcaseLightestBlue,
                    color: colors.swiftcaseWhite,
                    fontWeight: theme.fontWeights.swiftcaseMidweight,
                  }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
            )}
            {bulletPoint && (
              <ListItemIcon
                sx={{ alignSelf: "flex-start", paddingTop: "1rem" }}
              >
                <ArrowForwardIos
                  sx={{
                    fontSize: "1.5rem",
                    color: colors.swiftcaseLightestBlue,
                  }}
                />
              </ListItemIcon>
            )}
            <ListItemText
              primary={MarkdownText ? getMarkdownText(MarkdownText) : Text}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </ListItem>
          {Media && (
            <PaddedImage
              sxProps={{ marginX: "auto", marginY: "1.5rem" }}
              image={Media?.url}
              maxWidth="80%"
            />
          )}
        </Fragment>
      );
    });
  };

  return (
    <Grid item xs={12} className="dynamic-guide__grid-item">
      <Paper elevation={2}>
        <List>{getListItems(listData)}</List>
      </Paper>
    </Grid>
  );
};

export default ListElement;
