// External Imports
import React, { Fragment } from "react";
// Config
import {
  getColorScheme,
  getMaterialButton,
} from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  MediaCard,
  ThreeColumnFlex,
} from "../../../exports/components.exports";
import { Stack, Typography } from "@mui/material";

/**
 * @function ThreeColumnCards
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const ThreeColumnCards = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    LeftTitle,
    LeftSubtitle,
    CenterTitle,
    CenterSubtitle,
    RightTitle,
    RightSubtitle,
    ButtonPlacement,
    ElevatedCards,
    PanelTitle,
  } = blockData;

  const getButton = (position: "left" | "center" | "right") => {
    if (blockData?.Button && ButtonPlacement === position) {
      return getMaterialButton(blockData?.Button);
    }
    return undefined;
  };

  const getContent = (
    title: string,
    description: string,
    position: "left" | "center" | "right"
  ) => {
    return (
      <MediaCard
        data={{ title, description }}
        titleColor={getColorScheme(ColorScheme)?.primary}
        descriptionColor={getColorScheme(ColorScheme)?.secondary}
        transparentBackground={ElevatedCards ? false : true}
        height="fit-content"
        cardAction={getButton(position)}
        dynamicMediaCard
      />
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <Fragment>
        <Stack direction="column" spacing={4}>
          {PanelTitle && (
            <Typography
              variant="h2"
              color={getColorScheme(ColorScheme)?.primary}
              textAlign="center"
            >
              {PanelTitle}
            </Typography>
          )}
          <ThreeColumnFlex
            placement="space-between"
            leftPanelContentXPlacement="center"
            leftPanelContentYPlacement="top"
            centerPanelContentXPlacement="center"
            centerPanelContentYPlacement="top"
            rightPanelContentXPlacement="center"
            rightPanelContentYPlacement="top"
            left={getContent(LeftTitle, LeftSubtitle, "left")}
            center={getContent(CenterTitle, CenterSubtitle, "center")}
            right={getContent(RightTitle, RightSubtitle, "right")}
          />
        </Stack>
      </Fragment>
    </PanelWrapper>
  );
};

export default ThreeColumnCards;
