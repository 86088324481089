// External Imports
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// Material UI
import { Grid } from "@mui/material";
// Components
import { BlogPostCard } from "../../../exports/components.exports";

const query = graphql`
  query {
    allStrapiPost(sort: { fields: updated_at, order: DESC }, limit: 3) {
      edges {
        node {
          title
          description
          slug
          feature_image {
            url
            alternativeText
            localFile {
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          post_category {
            slug
            Name
          }
          updated_at(fromNow: true)
          created_at(formatString: "YYYY/MM/DD")
          writer {
            name
            picture {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @function ThreeRecentBlogs component
 * @returns the reusable recent blog posts grid
 */
const ThreeRecentBlogs = () => {
  const data = useStaticQuery(query);
  const threeRecentArticle = data.allStrapiPost.edges;

  const renderBlogPostGrid = () => {
    return threeRecentArticle.map((post: any, index: number) => {
      return (
        <Grid
          item
          lg={4}
          md={6}
          xs={12}
          className="post-card-grid__item"
          key={`post__${post.node.slug}`}
        >
          <BlogPostCard post={post.node} />
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      className="post-card-grid"
      sx={{ justifyContent: "space-between" }}
      spacing={4}
    >
      {renderBlogPostGrid()}
    </Grid>
  );
};
export default ThreeRecentBlogs;
