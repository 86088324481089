// External Imports
import React from "react";
// Config
import { colors } from "../../../exports/assets.exports";
// Components
import {
  PanelWrapper,
  CompanyReview,
} from "../../../exports/components.exports";

/**
 * @function DynamicCustomerReview
 * @param blockData from strapi
 * @returns the customer review
 */
const DynamicCustomerReview = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, ReviewAuthor, ReviewDescription, Image } = blockData;

  /**
   * @function getReviewColor
   * @param ColorScheme the color chosen on strapi
   * @returns the color scheme for this component only
   */
  const getReviewColor = (ColorScheme: string) => {
    switch (ColorScheme) {
      case "lightBlue":
        return {
          background: colors.swiftcaseMediumBlue,
          text: colors.swiftcaseWhite,
        };
      case "darkBlue":
        return {
          background: colors.swiftcaseBackgroundBlue,
          text: colors.swiftcaseWhite,
        };
      case "offWhite":
        return {
          background: colors.swiftcaseOffwhite,
          text: colors.swiftcaseDarkBlue,
        };
      default:
        return {
          background: colors.swiftcaseMediumBlue,
          text: colors.swiftcaseWhite,
        };
    }
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getReviewColor(ColorScheme).background}
      contentWidth="lg"
      centered
    >
      <CompanyReview
        image={Image.url}
        review={ReviewDescription}
        reviewContact={ReviewAuthor}
        reviewColor={getReviewColor(ColorScheme).text}
        reviewContactColor={getReviewColor(ColorScheme).text}
      />
    </PanelWrapper>
  );
};

export default DynamicCustomerReview;
