// Config
import { colors } from "../../../exports/assets.exports";

/**
 * @function getColorScheme
 * @param color a string color from strapi
 * @returns the background, primary and secondary colors
 */
const getColorScheme = (color: string) => {
  switch (color) {
    case "lightGrey":
      return {
        background: colors.swiftcaseWebsiteLightGrey,
        primary: colors.swiftcaseDarkBlue,
        secondary: colors.swiftcaseMidGrey,
      };
    case "offWhite":
      return {
        background: colors.swiftcaseOffwhite,
        primary: colors.swiftcaseDarkBlue,
        secondary: colors.swiftcaseDarkBlue,
      };
    case "white":
      return {
        background: colors.swiftcaseWhite,
        primary: colors.swiftcaseDarkBlue,
        secondary: colors.swiftcaseDarkBlue,
      };
    case "darkBlue":
      return {
        background: colors.swiftcaseBackgroundBlue,
        primary: colors.swiftcaseWhite,
        secondary: colors.swiftcaseOffwhite,
      };
    case "mediumBlue":
      return {
        background: colors.swiftcaseDarkBlue,
        primary: colors.swiftcaseLightestBlue,
        secondary: colors.swiftcaseWhite,
      };
    case "lightBlue":
      return {
        background: colors.swiftcaseMediumBlue,
        primary: colors.swiftcaseWhite,
        secondary: colors.swiftcaseOffwhite,
      };
    case "pink":
      return {
        background: colors.swiftcaseMediumPink,
        primary: colors.swiftcaseLightestPink,
        secondary: colors.swiftcaseWhite,
      };
    default:
      return {
        background: colors.swiftcaseWebsiteLightGrey,
        primary: colors.swiftcaseDarkBlue,
        secondary: colors.swiftcaseMidGrey,
      };
  }
};

export default getColorScheme;
