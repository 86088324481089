// Config
import { colors } from "../../../exports/assets.exports";

/**
 * @function getButtonColor
 * @param color a string color from strapi
 * @returns the button background color
 */
const getButtonColor = (color: string) => {
  switch (color) {
    case "darkBlue":
      return colors.swiftcaseDarkBlue;
    case "lightBlue":
      return colors.swiftcaseWebsiteBlue;
    case "pink":
      return colors.swiftcaseWebsitePink;
    case "green":
      return colors.swiftcaseGreen;
    case "offWhite":
      return colors.swiftcaseWhite;
    default:
      return colors.swiftcaseDarkBlue;
  }
};

export default getButtonColor;
