// External Imports
import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

/**
 * @function getMarkdownText
 * @param text a string to be passed into the component
 * @returns the converted markdown from the strapi data
 */
const getMarkdownText = (text: string) => {
  return (
    <Markdown
      rehypePlugins={[rehypeRaw]}
      children={text}
      className="dynamic-guide__markdown-text"
    />
  );
};

export default getMarkdownText;
