// External
import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Box,
  Grid,
  Typography,
  Container,
  Stack,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
// Assets
import { colors, theme } from "../../../exports/assets.exports";
// Components
import {
  LivepointFooter,
  PanelWrapper,
  TwoColumnFlex,
  StickyFooter,
} from "../../../exports/components.exports";
import { DynamicFreeTrialSignUp } from "../../templates/DynamicComponents/exports.dynamicComponents";
import DynamicFooterTwoColumn from "./DynamicFooterTwoColumn";
import DynamicFooterOneColumn from "./DynamicFooterOneColumn";
// Types
import {
  getMaterialButton,
  getSocialMediaIcon,
} from "../../../exports/functions.exports";

/**
 * Query to get the Data from strapiGlobal
 */
const query = graphql`
  query {
    strapiFooter {
      Footer
      ReverseTwoColumnLayout
      TextAndEmailColumns
      IncludeHelpToGrowBanner
    }
  }
`;

/**
 * @function WebsiteFooter component
 * @returns the website footer with dynamic options that can be set through strapi
 */
const WebsiteFooter = () => {
  const { strapiFooter } = useStaticQuery(query);
  const {
    Footer,
    TextAndEmailColumns,
    ReverseTwoColumnLayout,
    IncludeStickyFooter,
  } = strapiFooter;
  let footerTitle: any;
  let footerSubtitle: any;
  let footerEmailSignUp: any;
  let emailIconText: any = [];
  let footerButton: any;
  let footerMenus: any = [];
  let dynamicTwoColumn: any;
  let dyanmicOneColumn: any;

  /**
   * switch statement to map over the dynamic content of the website from strapi
   */
  Footer.map((footerBlock: any) => {
    switch (footerBlock.strapi_component) {
      case "sections.footer-two-column":
        dynamicTwoColumn = footerBlock;
        break;
      case "sections.footer-one-column":
        dyanmicOneColumn = footerBlock;
        break;
      case "shared.title":
        footerTitle = footerBlock;
        break;
      case "shared.subtitle":
        footerSubtitle = footerBlock;
        break;
      case "shared.button":
        footerButton = footerBlock;
        break;
      case "shared.email-sign-up":
        footerBlock.IconKeyPoint.map(
          ({ Text, Icon }: { Text: string; Icon: string }) =>
            emailIconText.push({ Text, Icon })
        );
        footerEmailSignUp = footerBlock;
        break;
      case "shared.footer-link-menu":
        footerMenus.push(footerBlock);
      default:
        break;
    }
  });

  /**
   * @function getTypography
   * @returns the material ui typography element with the passed in values of the variant, color and the text
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        textAlign="center"
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function TwoColTextEmailContent component
   * @returns the dynamic two column layout options for the website footer for the Title, Subtitle, Button, Email Sign Up
   */
  const TwoColTextEmailContent = () => {
    const leftContent = () => {
      return (
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "center" }}
          className="dynamic-footer-one-column"
          spacing={4}
        >
          {footerTitle && (
            <Grid item xs={12}>
              {getTypography("h2", footerTitle.Title, colors.swiftcaseWhite)}
            </Grid>
          )}
          {footerSubtitle && (
            <Grid item xs={12}>
              {getTypography(
                "body1",
                footerSubtitle.Subtitle,
                colors.swiftcaseWhite
              )}
            </Grid>
          )}
          {footerButton && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {getMaterialButton(footerButton)}
            </Grid>
          )}
        </Grid>
      );
    };

    const rightContent = () => {
      return (
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "center" }}
          className="dynamic-footer-one-column"
          spacing={4}
        >
          {footerEmailSignUp && (
            <Grid item>
              <DynamicFreeTrialSignUp
                buttonText={footerEmailSignUp.ButtonText}
                iconText={emailIconText}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      );
    };

    return (
      <PanelWrapper
        minHeight="10vh"
        backgroundColor={"inherit"}
        contentWidth="lg"
        centered
      >
        <TwoColumnFlex
          placement="space-between"
          right={ReverseTwoColumnLayout ? leftContent() : rightContent()}
          left={ReverseTwoColumnLayout ? rightContent() : leftContent()}
          leftPanelContentXPlacement="center"
          leftPanelContentYPlacement="top"
          rightPanelContentXPlacement="center"
          rightPanelContentYPlacement="top"
          noFixedHeight
        />
      </PanelWrapper>
    );
  };

  /**
   * @function OneColTextEmailContent component
   * @returns the dynamic one column layout options for the website footer for the Title, Subtitle, Button, Email Sign Up
   */
  const OneColTextEmailContent = () => {
    return (
      <PanelWrapper
        minHeight="10vh"
        backgroundColor={"inherit"}
        contentWidth="lg"
        centered
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "center" }}
          className="dynamic-footer-one-column"
          spacing={4}
        >
          {footerTitle && (
            <Grid item md={7} xs={12}>
              {getTypography("h2", footerTitle.Title, colors.swiftcaseWhite)}
            </Grid>
          )}
          {footerSubtitle && (
            <Grid item md={7} xs={12}>
              {getTypography(
                "body1",
                footerSubtitle.Subtitle,
                colors.swiftcaseWhite
              )}
            </Grid>
          )}
          {footerButton && (
            <Grid
              item
              md={7}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {getMaterialButton(footerButton)}
            </Grid>
          )}
          {footerEmailSignUp && (
            <Grid item xs={12}>
              <DynamicFreeTrialSignUp
                buttonText={footerEmailSignUp.ButtonText}
                iconText={emailIconText}
              />
            </Grid>
          )}
        </Grid>
      </PanelWrapper>
    );
  };

  /**
   * @function FooterMenus components
   * @returns the footer menus with nested links
   */
  const FooterMenus = ({ footerMenus }: { footerMenus: any }) => {
    /**
     * @function getMenuLinks
     * @returns the links to be displayed in the footer
     */
    const getMenuLinks = (linkArray: Array<any>, socialMedia: boolean) => {
      return linkArray.map((link: any, index: number) => {
        const { LinkText, LinkHref, SocialMediaIcon } = link;

        return (
          <ListItem disableGutters dense key={index}>
            <ListItemButton
              disableGutters
              href={LinkHref}
              sx={{
                color: colors.swiftcaseOffwhite,
                justifyContent: socialMedia ? "center" : "",
                "&:hover": {
                  color: colors.swiftcaseWhite,
                  textDecoration: `underline ${colors.swiftcaseOffwhite}`,
                },
              }}
            >
              {socialMedia ? getSocialMediaIcon(SocialMediaIcon) : LinkText}
            </ListItemButton>
          </ListItem>
        );
      });
    };

    /**
     * @function getFooterMenu
     * @returns the menu structure, with a call to get the links within, see function above
     */
    const getFooterMenu = (footerMenuArray: any) => {
      return footerMenuArray.map((menu: any, index: number) => {
        const { MenuTitle, Link, SocialMedia } = menu;
        return (
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={6}
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ width: SocialMedia ? "100%" : "initial" }}
            >
              <Typography
                variant="body1"
                color={colors.swiftcaseWhite}
                textAlign={SocialMedia ? "center" : "left"}
                fontWeight={theme.fontWeights.swiftcaseSemiBold}
              >
                {MenuTitle}
              </Typography>
              <List
                sx={{
                  display: SocialMedia ? "flex" : "",
                }}
              >
                {getMenuLinks(Link, SocialMedia)}
              </List>
            </Stack>
          </Grid>
        );
      });
    };

    return (
      <Grid container spacing={4} className="dynamic-footer-menus">
        {getFooterMenu(footerMenus)}
      </Grid>
    );
  };

  return (
    <Fragment>
      <Box
        className="website-footer__container"
        sx={{
          width: "100%",
          minHeight: "40rem",
          zIndex: 30,
          backgroundColor: colors.swiftcaseBackgroundBlue,
        }}
      >
        {TextAndEmailColumns === "one" && <OneColTextEmailContent />}
        {TextAndEmailColumns === "two" && <TwoColTextEmailContent />}
        {dyanmicOneColumn && (
          <DynamicFooterOneColumn blockData={dyanmicOneColumn} />
        )}
        {dynamicTwoColumn && (
          <DynamicFooterTwoColumn blockData={dynamicTwoColumn} />
        )}
        <Box
          sx={{ position: "relative", zIndex: 30, backgroundColor: "inherit" }}
        >
          <Container maxWidth="lg" sx={{ position: "relative", zIndex: 30 }}>
            {footerMenus && <FooterMenus footerMenus={footerMenus} />}
          </Container>
        </Box>
        <LivepointFooter />
      </Box>
      {IncludeStickyFooter && <StickyFooter />}
    </Fragment>
  );
};

export default WebsiteFooter;
