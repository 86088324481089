// External Imports
import React from "react";
// Components
import {
  TwoColumnFlex,
  ContactUsForm,
  CallUs,
  PanelWrapper,
  BorderedTextParagraph,
  PaddedImage,
} from "../../../exports/components.exports";
// Material UI
import { Box, Grid, Typography } from "@mui/material";
// Config
import { colors, GreenDots, BlueDots } from "../../../exports/assets.exports";
import {
  getImageSize,
  getMarkdownText,
} from "../../../exports/functions.exports";

/**
 * @function DynamicContactUsForm
 * @returns the Contact Us page
 */
const DynamicContactUsForm = ({ blockData }: { blockData: any }) => {
  const {
    Title,
    Description,
    IncludePhoneNumber,
    BackgroundImage,
    RightImageSize,
  } = blockData;

  /**
   * @function getBackgroundImage
   * @returns get the background image for the panel
   */
  const getBackgroundImage = (image: string) => {
    switch (image) {
      case "greenDots":
        return GreenDots;
      case "blueDots":
        return BlueDots;
      default:
        return undefined;
    }
  };

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getImageContent
   * @param image to be rendered
   * @returns the image inside a padded image component
   */
  const getImageContent = (image: any, imageSize: string) => {
    return (
      <PaddedImage
        noBorder={0}
        noShadow="none"
        image={image.url}
        backgroundColor="transparent"
        maxWidth={getImageSize(imageSize)}
      />
    );
  };

  /**
   * @function getBorderedText
   * @param paragraphs an array of strings
   * @returns the bordered text component
   */
  const getBorderedText = (paragraphs: Array<string>) => {
    return (
      <Grid item xs={12}>
        <BorderedTextParagraph
          borderColor={colors.swiftcaseMediumBlue}
          border="left"
          paragraphs={paragraphs}
          fontColor={colors.swiftcaseDarkBlue}
        />
      </Grid>
    );
  };

  /**
   * @function getSimpleRepeatedTypography
   * @param paragraphs an array of strings
   * @returns a grid item and typography element for each string in the array, of body text, and secondary color scheme
   */
  const getSimpleRepeatedTypography = (paragraphs: Array<string>) => {
    return paragraphs.map((text: string, index: number) => {
      return (
        <Grid item xs={12} key={index}>
          {getTypography("body1", text, colors.swiftcaseDarkBlue)}
        </Grid>
      );
    });
  };

  const rightContent = () => {
    let paragraphs: Array<string | any> = [];
    blockData?.RightParagraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    if (
      !blockData?.RightImage &&
      blockData?.RightParagraphs.length === 0 &&
      !IncludePhoneNumber
    )
      return undefined;

    return (
      <Grid container spacing={4} className="complex-two-column__right">
        {/* If RightParagraphs and RightColumnParagraphBorderedText === true return the bordered text element else return simple Typography components */}
        {blockData?.RightParagraphs &&
        blockData?.RightColumnParagraphBorderedText
          ? getBorderedText(paragraphs)
          : getSimpleRepeatedTypography(paragraphs)}
        {blockData?.RightImage && (
          <Grid
            item
            xs={12}
            sx={{
              ".padded-image__wrapper": {
                marginX: "auto",
              },
            }}
          >
            {getImageContent(blockData?.RightImage, RightImageSize)}
          </Grid>
        )}
        {IncludePhoneNumber && (
          <Grid item xs={12}>
            <CallUs />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="50vh"
      contentWidth="lg"
      backgroundColor={colors.swiftcaseWebsiteLightGrey}
    >
      <Box position="relative" className="contact-us-page">
        {BackgroundImage === "none" ? null : (
          <Box
            sx={{
              position: "absolute",
              top: { xs: 500, md: 50 },
              right: { xs: -100, md: -300, xl: -200 },
              zIndex: 1,
              maxWidth: { xs: "40rem", sm: "50rem", md: "90rem", xl: "100rem" },
            }}
          >
            <img
              src={getBackgroundImage(BackgroundImage)}
              alt="Green Dots Background"
              style={{ width: "100%", height: "100%", opacity: 0.2 }}
              loading="lazy"
            />
          </Box>
        )}
        <TwoColumnFlex
          contentWidth="lg"
          placement="space-between"
          left={<ContactUsForm title={Title} description={Description} />}
          right={rightContent()}
          leftPanelContentXPlacement="center"
          leftPanelContentYPlacement="top"
          rightPanelContentXPlacement="right"
          rightPanelContentYPlacement="bottom"
          noFixedHeight
        />
      </Box>
    </PanelWrapper>
  );
};
export default DynamicContactUsForm;
