// External
import React from "react";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
// Assets
import { theme, colors } from "../../../exports/assets.exports";

/**
 * @function CallUs
 * @returns Call us component
 */
const CallUs = () => {
  return (
    <Stack
      direction="column"
      alignItems="flex-end"
      className="call-us-container"
    >
      <Box>
        <Typography
          variant="h2"
          color={colors.swiftcaseDarkBlue}
          sx={{ marginY: "2rem" }}
        >
          ...Rather call us?
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <LocalPhoneTwoToneIcon
          sx={{ color: colors.swiftcaseBlue, marginRight: "1rem" }}
        />
        <Link
          href="tel:+441512943177"
          variant="body1"
          fontWeight={theme.fontWeights.swiftcaseSemiBold}
          color={colors.swiftcaseDarkBlue}
          sx={{ marginY: "2rem" }}
        >
          +44 (0) 151 294 3177
        </Link>
      </Box>
    </Stack>
  );
};

export default CallUs;
