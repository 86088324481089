// External
import { Box, Container, Grid } from "@mui/material";
import React from "react";
// Config
import { ICallToActionBannerProps } from "../../../exports/types.exports";

/**
 *
 * @param ICallToActionBannerProps
 * @returns CallToActionBanner component
 */
const CallToActionBanner = ({
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  headerContent,
  bodyContent,
  footerContent,
  minHeight,
  centerHeader,
}: ICallToActionBannerProps) => {
  return (
    <Box
      className="call-to-action-banner"
      sx={{
        width: "100%",
        minHeight: minHeight ?? null,
        backgroundColor,
        backgroundImage: `url(${backgroundImage})` || null,
        backgroundPosition,
        backgroundSize,
      }}
    >
      <Container>
        <Grid
          container
          className="call-to-action__content"
          height="100%"
          flexDirection="column"
          sx={{ minHeight: minHeight ?? null, paddingY: "10rem" }}
          spacing={4}
        >
          {headerContent && (
            <Grid
              item
              xs={12}
              className="call-to-action__header"
              display="flex"
              alignItems="center"
              justifyContent={centerHeader ?? ""}
            >
              {headerContent}
            </Grid>
          )}

          {bodyContent && (
            <Grid
              item
              className="call-to-action__body"
              display="flex"
              alignItems="center"
              md={7}
              xs={12}
            >
              {bodyContent}
            </Grid>
          )}

          {footerContent && (
            <Grid
              item
              xs={12}
              className="call-to-action__footer"
              display="flex"
              alignItems="center"
            >
              {footerContent}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default CallToActionBanner;
