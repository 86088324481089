// External Imports
import React, { Fragment } from "react";
// Components
import {
  PanelWrapper,
  ApplyNowForm,
} from "../../../exports/components.exports";
// Material UI
import { Paper, Grid, Typography } from "@mui/material";
// Config
import {
  colors,
  PanelFourBackground,
  HeaderBackground,
} from "../../../exports/assets.exports";
/**
 * @function DynamicPartnerApply
 * @returns the Partner Apply form
 */
const DynamicPartnerApply = ({ blockData }: { blockData: any }) => {
  const { Title, Description, TagLine, BackgroundColor } = blockData;

  /**
   * @function PartnerApplyForm
   * @returns the partners apply now form
   */
  const PartnerApplyForm = () => {
    return (
      <Fragment>
        <Paper className="partner-apply-form__paper-box">
          <Grid
            container
            sx={{
              width: "90%",
              alignItems: "center",
              paddingTop: "3rem",
              margin: "auto",
            }}
            className="partner-apply-form"
          >
            <Grid item xs={12} className="partner-apply-form__grid-item">
              <Typography
                color={colors.swiftcaseWebsiteBlue}
                className="partner-apply-form__header"
                variant="h5"
                textAlign="center"
                sx={{ padding: 0, textTransform: "uppercase" }}
              >
                {TagLine}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className="partner-apply-form__grid-item"
              sx={{ marginY: "2rem" }}
            >
              <Typography
                color={colors.swiftcaseDarkBlue}
                className="partner-apply-form__header"
                variant="h2"
                textAlign="center"
                sx={{ padding: 0 }}
              >
                {Title}
              </Typography>
            </Grid>
            <Grid item xs={12} className="partner-apply-form__grid-item">
              <Typography
                className="partner-apply-form__card-title"
                color={colors.swiftcaseDarkBlue}
                textAlign="center"
                variant="body1"
                sx={{ padding: 0 }}
              >
                {Description}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginY: "3rem",
              }}
              className="partner-apply-form__grid-item"
            >
              <ApplyNowForm />
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  };

  return (
    <PanelWrapper
      minHeight="50vh"
      backgroundColor={
        BackgroundColor === "lightGrey"
          ? colors.swiftcaseWebsiteLightGrey
          : colors.swiftcaseDarkBlue
      }
      backgroundImage={
        BackgroundColor === "lightGrey" ? HeaderBackground : PanelFourBackground
      }
      backgroundPosition="top"
      backgroundSize="cover"
      contentWidth="lg"
      centered
    >
      <PartnerApplyForm />
    </PanelWrapper>
  );
};
export default DynamicPartnerApply;
