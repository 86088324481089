import { Box, Grid } from "@mui/material";
import React from "react";
import { IThreeColumnFlexProps } from "../../../exports/types.exports";
import { getPanelConentPlacement } from "../../../exports/functions.exports";

const ThreeColumnFlex = ({
  contentWidth,
  minHeight,
  placement,
  alignment,
  left,
  center,
  right,
  leftPanelContentXPlacement,
  leftPanelContentYPlacement,
  leftPanelHeight,
  centerPanelContentXPlacement,
  centerPanelContentYPlacement,
  centerPanelHeight,
  rightPanelContentXPlacement,
  rightPanelContentYPlacement,
  rightPanelHeight,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  color,
  alterZIndex,
  wrapReverse,
}: IThreeColumnFlexProps) => {
  return (
    <Box
      className="three-column-flex"
      display="flex"
      justifyContent="center"
      sx={{
        minHeight,
        backgroundColor,
        backgroundImage: `url(${backgroundImage})` || null,
        backgroundPosition,
        backgroundSize,
        color,
        height: "100%",
        width: "100%",
        position: "relative",
        zIndex: alterZIndex ?? 20,
      }}
    >
      <Grid
        container
        maxWidth={contentWidth}
        display="flex"
        justifyContent={placement}
        alignItems={alignment}
        sx={{
          flexWrap: { md: "initial", xs: wrapReverse ? "wrap-reverse" : "" },
        }}
      >
        <Grid
          item
          className="three-column-flex__left"
          display="flex"
          justifyContent={getPanelConentPlacement(leftPanelContentXPlacement!)}
          alignItems={getPanelConentPlacement(leftPanelContentYPlacement!)}
          height={{ md: leftPanelHeight ?? "100%" }}
          width="30%"
          md={3.5}
          xs={12}
          sx={{ marginTop: wrapReverse ? { md: "0", xs: "3rem" } : "0" }}
        >
          {left}
        </Grid>
        <Grid
          item
          className="three-column-flex__center"
          display="flex"
          justifyContent={getPanelConentPlacement(
            centerPanelContentXPlacement!
          )}
          alignItems={getPanelConentPlacement(centerPanelContentYPlacement!)}
          height={{ md: centerPanelHeight ?? "100%" }}
          width="30%"
          md={3.5}
          xs={12}
          sx={{ marginTop: { md: "0", xs: "3rem" } }}
        >
          {center}
        </Grid>
        <Grid
          item
          className="three-column-flex__right"
          display="flex"
          justifyContent={getPanelConentPlacement(rightPanelContentXPlacement!)}
          alignItems={getPanelConentPlacement(rightPanelContentYPlacement!)}
          height={{ md: rightPanelHeight ?? "100%" }}
          width="30%"
          md={3.5}
          xs={12}
          sx={{ marginTop: wrapReverse ? "0" : { md: "0", xs: "3rem" } }}
        >
          {right}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThreeColumnFlex;
