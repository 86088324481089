// External Imports
import React from "react";
import { navigate } from "gatsby";
// Config
import { colors } from "../../../exports/assets.exports";
// Components
import { MenuBook } from "@mui/icons-material";
import {
  Grid,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";

/**
 * @function GetSimilarGuides component
 * @param similarGuideData data from strapi relating to the similar guides
 * @returns a list of similar guides and links to them, within a grid item
 */
const GetSimilarGuides = ({ similarGuideData }: { similarGuideData: any }) => {
  /**
   * @function getSimilarGuides
   * @returns a list of similar guides that are links to them
   */
  const getSimilarGuides = () => {
    return similarGuideData.map(
      ({ Slug, Title }: { Slug: string; Title: string }) => {
        return (
          <ListItem
            key={Slug}
            disableGutters
            onClick={() => navigate(`/guides/article/${Slug}`)}
            sx={{
              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            <ListItemIcon>
              <MenuBook />
            </ListItemIcon>
            <ListItemText color={colors.swiftcaseDarkBlue}>
              {Title}
            </ListItemText>
          </ListItem>
        );
      }
    );
  };

  return (
    <Grid item xs={12} className="dynamic-guide__grid-item-similar-guides">
      <Typography
        variant="h3"
        className="dynamic-guide__see-also-similar-guide"
        color={colors.swiftcaseDarkBlue}
      >
        See Also
      </Typography>
      <List dense>{getSimilarGuides()}</List>
    </Grid>
  );
};

export default GetSimilarGuides;
