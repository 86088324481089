// External
import React, { Fragment, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
// Assets
import { colors } from "../../../exports/assets.exports";
// Config
import { IEmailSignUpProps } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";

/**
 * @function EmailSignUp
 * @returns the email sign-up component for the non-homepage website content
 *
 * @todo implement the button and email input functionality
 */
const EmailSignUp = ({ buttonText, infoTextColor }: IEmailSignUpProps) => {
  const [emailAddress, setEmailAddress] = useState("");

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={3}
        maxWidth="90%"
        className="swiftcase-email-sign-up"
      >
        <Grid item xs={12} className="swiftcase-email-sign-up__grid-item">
          <TextField
            onChange={(e) => setEmailAddress(e.target.value)}
            margin="normal"
            className="swiftcase-email-sign-up__input"
            label="Enter your business email"
            size="medium"
            type="input"
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: colors.swiftcaseWhite, minWidth: "18rem" }}
            color="success"
          />
        </Grid>
        <Grid item xs={12} className="swiftcase-email-sign-up__grid-item">
          <Grid container direction="row" spacing={2}>
            <Grid item xs="auto">
              <Button
                variant="contained"
                className="swiftcase-email-sign-up__button"
                size="medium"
                href={`${urlRoutes.CRM_SIGNUP}?emailAddress=${emailAddress}`}
                sx={{
                  height: "100%",
                  backgroundColor: colors.swiftcaseGreen,
                  ":hover": {
                    backgroundColor: colors.swiftcaseGreen,
                    color: colors.swiftcaseWhite,
                    textDecoration: "underline",
                  },
                }}
              >
                {buttonText}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ marginY: "1.5rem" }}
              className="swiftcase-email-sign-up__grid-item"
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color={infoTextColor}
                    sx={{
                      paddingBottom: "0",
                    }}
                  >
                    *No credit card required
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="swiftcase-email-sign-up__grid-item"
                >
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color={infoTextColor}
                    sx={{
                      paddingBottom: "0",
                    }}
                  >
                    By entering your email you agree to receive marketing emails
                    from the SwiftCase Team.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EmailSignUp;
