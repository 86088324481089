// External Imports
import React from "react";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { getMarkdownText } from "../../../exports/functions.exports";
// Components
import { NoteAltOutlined } from "@mui/icons-material";
import { Grid, Typography, CardHeader, Card, CardContent } from "@mui/material";

/**
 * @function NoteElement component
 * @param nodeText data from strapi for the relevant component
 * @returns the note element, within a grid item
 */
const NoteElement = ({ nodeText }: { nodeText: any }) => {
  const { MarkdownText, PlainText } = nodeText;
  return (
    <Grid item xs={12} className="dynamic-guide__grid-item--note-element">
      <Card
        className="dynamic-guide__note-element"
        sx={{
          backgroundColor: colors.swiftcaseLightBlueOpaque,
          border: `0.125rem solid ${colors.swiftcaseLightBlue}`,
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={
            <NoteAltOutlined
              sx={{ fontSize: "3rem", color: colors.swiftcaseMediumGreen }}
            />
          }
          titleTypographyProps={{
            variant: "h4",
            fontWeight: theme.fontWeights.swiftcaseSemiBold,
          }}
          title="Note:"
        />
        <CardContent sx={{ backgroundColor: "transparent" }}>
          <Typography variant="body1" color={colors.swiftcaseDarkBlue}>
            {PlainText ? PlainText : getMarkdownText(MarkdownText)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NoteElement;
