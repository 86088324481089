// External
import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
// Assets
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import { getListIcon } from "../../../exports/functions.exports";

/**
 *
 * @param alignment used as a value to css property justify-content in the enclosing grid container
 * @returns DynamicFreeTrialSignup component
 */
const DynamicFreeTrialSignUp = ({
  buttonText,
  iconText,
  fullWidth = false,
}: {
  buttonText: string;
  iconText: any;
  fullWidth?: boolean;
}) => {
  const [emailAddress, setEmailAddress] = useState("");

  /**
   * @function returnIconTextInfo
   * @returns the icons and adjoining text for the info/ley points under the email sign up form
   */
  const returnIconTextInfo = () => {
    return iconText.map((iconData: any) => {
      let { Text, Icon } = iconData;
      return (
        <Grid item key={Text}>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {getListIcon(Icon, "darkBlue")} {Text}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      sx={{ justifyContent: "center", zIndex: 15 }}
      className="free-trail-sign-up"
    >
      <Grid item xs={12} md={fullWidth ? 12 : 7}>
        <TextField
          onChange={(e) => setEmailAddress(e.target.value)}
          margin="normal"
          label="Enter your business email"
          size="medium"
          type="input"
          fullWidth
          variant="filled"
          sx={{
            backgroundColor: colors.swiftcaseWhite,
            minWidth: "18rem",
            ".MuiInputLabel-root": {
              marginTop: "0.4rem",
              fontSize: "1.6rem",
            },
          }}
          color="success"
        />
      </Grid>
      <Grid item md={fullWidth ? 12 : 7} xs={12}>
        <Grid
          container
          direction="row"
          spacing={2}
          className="free-trial-sign-up__button-text-grid"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item md={5} xs="auto">
            <Button
              variant="contained"
              size="medium"
              href={`${urlRoutes.CRM_SIGNUP}?emailAddress=${emailAddress}`}
              sx={{
                backgroundColor: colors.swiftcaseGreen,
                ":hover": {
                  backgroundColor: colors.swiftcaseGreen,
                  textDecoration: "underline",
                },
              }}
            >
              {buttonText}
            </Button>
          </Grid>
          <Grid item xs={12} md={6.5}>
            <Grid container sx={{ justifyContent: "flex-end" }}>
              {returnIconTextInfo()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DynamicFreeTrialSignUp;
