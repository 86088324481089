import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { colors, theme } from "../../exports/assets.exports";
import { ICustomBreadcrumbProps } from "../../exports/types.exports";

/**
 * @function CustomBreadcrumb
 * @returns the breadcrumb with one, two or three links/paths
 */
const CustomBreadcrumb = ({
  primary,
  secondary,
  currentPage,
  invertFontColor = false,
}: ICustomBreadcrumbProps) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator=">"
      sx={{ fontSize: "1.5rem" }}
    >
      {primary && (
        <Link
          underline="hover"
          color={
            invertFontColor
              ? colors.swiftcaseOffwhite
              : colors.swiftcaseMediumBlue
          }
          href={primary.href}
        >
          {primary.text}
        </Link>
      )}
      {secondary && (
        <Link
          underline="hover"
          color={
            invertFontColor
              ? colors.swiftcaseOffwhite
              : colors.swiftcaseMediumBlue
          }
          href={secondary.href}
        >
          {secondary.text}
        </Link>
      )}
      <Typography
        color={
          invertFontColor ? colors.swiftcaseWhite : colors.swiftcaseDarkBlue
        }
        variant="h5"
        fontWeight={theme.fontWeights.swiftcaseMidweight}
      >
        {currentPage}
      </Typography>
    </Breadcrumbs>
  );
};

export default CustomBreadcrumb;
