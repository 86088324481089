import { IResponse, ITaskKeyValuePair } from "../../exports/types.exports";

/**
 *
 * @param workflowTypeId Id of workflow to create task against
 * @param data request body
 * @param response IResponse response object which is updated throught method
 * @function createTask Makes server request to create a Contact Us task
 * @returns Updated IResponse response object
 */
const createTask = async (
  workflowTypeId: string,
  data: Array<ITaskKeyValuePair>,
  response: IResponse
) => {
  const requestBody = {
    workflow_type_id: workflowTypeId,
    data: data,
  };

  // configuration for API request
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(requestBody),
  };

  // API Request built from config
  await fetch(
    `${process.env.REACT_APP_SWIFTCASE_API_URI}/${process.env.REACT_APP_CONTACT_US_API_KEY}/task.json`,
    config
  )
    .then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        response.isAccepted = true;
      }

      return res.json();
    })
    .then((res) => {
      response.message = res;

      return response;
    })
    .catch((error) => {
      console.error(error);
      response.isAccepted = false;
      response.message = error;

      return response;
    });

  return response;
};

export default createTask;
