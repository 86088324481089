// External Import
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
// Material UI
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
  IconButton,
  Avatar,
} from "@mui/material";
import { Person, ArrowRightAlt } from "@mui/icons-material";
// Config
import { colors, SwiftcaseLogo, theme } from "../../exports/assets.exports";
import { IGuide } from "../../exports/types.exports";
import { urlRoutes } from "../../exports/constants.exports";

/**
 * @function GuideCard
 * @param guide details to be rendered
 * @returns the guide card
 */
const GuideCard = ({ guide }: { guide: IGuide }) => {
  const {
    Description,
    Slug,
    Title,
    Author,
    Category,
    updated_at,
    FeatureImage,
  } = guide.node;
  const avatarImage = getImage(Author?.picture?.localFile);
  const featureImage = getImage(FeatureImage?.localFile);

  const handleClick = (type: string) =>
    type === "guide"
      ? navigate(`${urlRoutes.GUIDES}/article/${Slug}/`)
      : navigate(`${urlRoutes.GUIDES}/${Category.slug}/`);

  return (
    <Card
      className="guide-card"
      variant="elevation"
      elevation={4}
      sx={{
        height: "60rem",
        width: "100%",
        maxWidth: "40rem",
        position: "relative",
        paddingBottom: "2rem",
        marginX: "auto",
        ":hover": {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          transition: "box-shadow 0.25s ease-in-out 0.1s",
        },
      }}
    >
      {featureImage ? (
        <GatsbyImage
          image={featureImage}
          alt={Title}
          style={{
            height: "20rem",
          }}
          imgStyle={{ objectPosition: "center", objectFit: "scale-down" }}
        />
      ) : (
        <CardMedia
          image={SwiftcaseLogo}
          component="img"
          alt={`${Title} card-image`}
          onClick={() => handleClick("guide")}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "20rem",
            ":hover": {
              cursor: "pointer",
            },
          }}
        />
      )}
      <IconButton
        sx={{
          backgroundColor: colors.swiftcaseWhite,
          boxShadow: theme.shadows[4],
          position: "absolute",
          bottom: "2rem",
          right: "2rem",
          ":hover": {
            cursor: "pointer",
          },
        }}
        size="small"
        onClick={() => handleClick("guide")}
      >
        <ArrowRightAlt
          sx={{ fontSize: "3rem", color: colors.swiftcaseWebsiteBlue }}
        />
      </IconButton>
      <CardHeader
        className="guide-card__header"
        sx={{
          marginY: "1rem",
          paddingX: "2rem",
        }}
        disableTypography
        title={
          <Typography
            variant="h5"
            color={colors.swiftcaseWebsiteBlue}
            sx={{
              marginBottom: "2rem",
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleClick("category")}
          >
            {`${Category.name}`}{" "}
            <Typography
              variant="inherit"
              component="span"
              color={colors.swiftcaseGrey}
            >
              {" "}
              {`| ${updated_at}`}
            </Typography>
          </Typography>
        }
        subheader={
          <Typography
            variant="h4"
            color={colors.swiftcaseDarkBlue}
            className="guide-card__title title-line-clamp"
            onClick={() => handleClick("guide")}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            {Title}
          </Typography>
        }
      />
      <CardContent
        className="guide-card__content"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "4.5rem",
          paddingTop: 0,
          paddingX: "2rem",
        }}
      >
        <Typography
          variant="body2"
          className="card__description text-description-line-clamp"
          color={colors.swiftcaseDarkBlue}
          sx={{
            opacity: 0.8,
            marginBottom: "3rem",
          }}
        >
          {Description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          position: "absolute",
          bottom: "1rem",
          paddingX: "2rem",
        }}
      >
        {avatarImage ? (
          <GatsbyImage
            loading="lazy"
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              marginRight: "1rem",
            }}
            image={avatarImage}
            alt={Author.name ?? "The Swiftcase Team"}
          />
        ) : (
          <Avatar
            sx={{
              marginRight: "1rem",
              height: "4rem",
              width: "4rem",
              backgroundColor: colors.swiftcaseDarkBlue,
            }}
          >
            <Person />
          </Avatar>
        )}
        <Typography
          variant="h5"
          className="card__author-footer"
          color={colors.swiftcaseGrey}
          sx={{
            padding: 0,
          }}
        >
          By
          <Typography
            variant="inherit"
            component="span"
            color={colors.swiftcaseWebsiteBlue}
          >
            {" "}
            {Author.name ?? "The Swiftcase Team"}
          </Typography>
        </Typography>
      </CardActions>
    </Card>
  );
};

export default GuideCard;
