// External Imports
import React from "react";
// Config
import { getColorScheme } from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  TextPanelTitleSubtitle,
  BlogPostCard,
} from "../../../exports/components.exports";

/**
 * @function TwoColumnBlogCard
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const TwoColumnBlogCard = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, ReverseColumnLayout, Subtitle, Title, BlogPost } =
    blockData;

  const rightContent = () => {
    return <BlogPostCard post={BlogPost} />;
  };

  const leftContent = () => {
    return (
      <TextPanelTitleSubtitle
        title={Title}
        subTitle={Subtitle}
        titleColor={getColorScheme(ColorScheme)?.primary}
        subTitleColor={getColorScheme(ColorScheme)?.secondary}
      />
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default TwoColumnBlogCard;
