// External Imports
import React from "react";
// Config
import { colors } from "../../../exports/assets.exports";
import { ICustomerReviews } from "../../../exports/types.exports";
// Components
import {
  PanelWrapper,
  SteppedCarousel,
} from "../../../exports/components.exports";
import { Grid, Box } from "@mui/material";

/**
 * @function DynamicCarousel
 * @returns the dynamic carousel ie for Customer Reviews
 */
const DynamicCarousel = ({ blockData }: { blockData: any }) => {
  const { CarouselData } = blockData;
  let carouselData: Array<ICustomerReviews> = [];
  let isAnyStars = false;

  CarouselData.map((carouselItem: any) => {
    const { Title, LogoImage, FiveStars, Description } = carouselItem;
    if (FiveStars) {
      isAnyStars = true;
    }
    carouselData.push({
      subtitle: Title,
      description: Description,
      image: LogoImage?.url,
      fiveStars: FiveStars,
    });
  });

  return (
    <PanelWrapper
      minHeight="65vh"
      backgroundColor={colors.swiftcaseDarkestBlue}
      contentWidth="lg"
      centered
    >
      <Grid container className="dynamic-carousel">
        <Grid
          xs={12}
          item
          sx={{
            marginX: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SteppedCarousel data={carouselData} stars={isAnyStars} />
          </Box>
        </Grid>
      </Grid>
    </PanelWrapper>
  );
};

export default DynamicCarousel;
