// External Imports
import React, { Fragment, useEffect, useState } from "react";
// Config
import { colors, HeaderBackground } from "../../../exports/assets.exports";
import { getColorScheme } from "../../../exports/functions.exports";
import { ICardData } from "../../../exports/types.exports";
// Components
import {
  PanelWrapper,
  MediaCard as MaterialMediaCard,
} from "../../../exports/components.exports";
import { Typography, Grid } from "@mui/material";

/**
 * @function DynamicMediaCardGrid
 * @returns the dynamic media card grid
 */
const DynamicMediaCardGrid = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, Title, MediaCard, Description, UseDottedBackground } =
    blockData;
  const [formattedCardData, setFormattedCardData] = useState<Array<ICardData>>(
    []
  );

  useEffect(() => {
    let tmpCardData: Array<ICardData> = [];

    MediaCard.map((card: any) => {
      tmpCardData.push({
        img: card.Image.url,
        title: card.Title,
        description: card.Subtitle,
      });
    });

    setFormattedCardData(tmpCardData);
  }, []);

  const MediaCardGrid = ({ cardData }: { cardData: any }) => {
    const getCardGridItem = () => {
      return cardData.map((item: any, index: number) => {
        return (
          <Grid
            item
            key={`${item.title}-${index}`}
            lg={4}
            md={6}
            xs={12}
            className="product-example-card-grid__grid-item"
          >
            <MaterialMediaCard
              data={item}
              height="fit-content"
              backgroundColor={colors.swiftcaseWhite}
              descriptionColor={colors.swiftcaseMediumBlue}
            />
          </Grid>
        );
      });
    };

    return (
      <Fragment>
        <Grid
          container
          justifyContent="space-between"
          className="product-example-card-grid"
          spacing={4}
        >
          {cardData && getCardGridItem()}
        </Grid>
      </Fragment>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      backgroundImage={UseDottedBackground ? HeaderBackground : undefined}
      backgroundPosition="center"
      backgroundSize="cover"
      centered
    >
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "center" }}
        className="dynamic-media-card"
        spacing={4}
      >
        <Grid item xs={12} className="dynamic-media-card__grid-item">
          <Typography
            className="dynamic-media-card__header"
            variant="h2"
            sx={{ color: getColorScheme(ColorScheme)?.primary }}
          >
            {Title}
          </Typography>
        </Grid>
        {Description && (
          <Grid item xs={12} className="dynamic-media-card__grid-item">
            <Typography
              className="dynamic-media-card__description"
              variant="body1"
              sx={{ color: getColorScheme(ColorScheme)?.secondary }}
            >
              {Description}
            </Typography>
          </Grid>
        )}
        {formattedCardData && (
          <Grid item xs={12}>
            <MediaCardGrid cardData={formattedCardData} />
          </Grid>
        )}
      </Grid>
    </PanelWrapper>
  );
};

export default DynamicMediaCardGrid;
