// External Imports
import React, { Fragment } from "react";
// Components
import {
  PanelWrapper,
  CareersPosition,
  ContactUsButton,
  SinglePanelLayout,
  SkeletonCard,
} from "../../../exports/components.exports";
// Config
import { urlRoutes } from "../../../exports/constants.exports";
import { colors } from "../../../exports/assets.exports";
import { Grid } from "@mui/material";

/**
 * @function CareersAvailablePosition
 * @returns the dynamic careers page
 */
const CareersAvailablePosition = ({ blockData }: { blockData: any }) => {
  const { AvailableJobPosition, NoRoles } = blockData;

  /**
   *
   * @returns List of CallToActionBanner with contents of available positions
   */
  const getAvailablePositions = () => {
    return AvailableJobPosition.map((position: any, index: number) => {
      const {
        Title,
        Description,
        Salary,
        Benefits,
        Qualifications,
        Responsibilities,
      } = position;
      return (
        <Fragment key={position.id}>
          <PanelWrapper
            minHeight="10vh"
            backgroundColor={colors.swiftcaseWebsiteLightGrey}
            contentWidth="lg"
            centered
          >
            <SinglePanelLayout
              justifyFooter="flex-start"
              complexTitle={
                <CareersPosition
                  jobTitle={Title}
                  jobSummary={Description}
                  responsibilities={Responsibilities}
                  qualifications={Qualifications}
                  salary={Salary}
                  benefits={Benefits}
                />
              }
              footer={<ContactUsButton />}
            />
          </PanelWrapper>
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      {getAvailablePositions()}
      {NoRoles && (
        <PanelWrapper
          minHeight="50vh"
          backgroundColor={colors.swiftcaseWebsiteLightGrey}
          contentWidth="lg"
          centered
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6} className="guide-category__no-blogs">
              <SkeletonCard
                alternateButtonText="Return To Homepage"
                alternateMessage="We currently don't have any roles available, but please check back again soon."
                link={urlRoutes.HOMEPAGE}
              />
            </Grid>
          </Grid>
        </PanelWrapper>
      )}
    </Fragment>
  );
};

export default CareersAvailablePosition;
