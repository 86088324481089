// External Imports
import React, { Fragment } from "react";
// Material UI
import { Typography, TextField, InputAdornment } from "@mui/material";
// Config
import { colors, ArrowMaskBackround } from "../../../exports/assets.exports";
import { CallToActionBanner } from "../../../exports/components.exports";
import { Search } from "@mui/icons-material";

/**
 * @function DocsCallToAction
 * @returns the call to action for docs category
 */
const DocsCallToAction = ({
  handleSearchCallback,
  showSearchBar = false,
}: {
  handleSearchCallback?: (query: string) => void;
  showSearchBar?: boolean;
}) => {
  return (
    <Fragment>
      <CallToActionBanner
        backgroundColor={colors.swiftcaseBackgroundBlue}
        backgroundImage={ArrowMaskBackround}
        backgroundPosition="top"
        backgroundSize="cover"
        headerContent={
          <Typography
            variant="h1"
            className="docs-call-to-action__header"
            color={colors.swiftcaseWhite}
          >
            Guides
          </Typography>
        }
        bodyContent={
          <Typography
            variant="body1"
            className="docs-call-to-action__body"
            color={colors.swiftcaseWhite}
          >
            How Can We Help?
          </Typography>
        }
        footerContent={
          showSearchBar && handleSearchCallback ? (
            <TextField
              onChange={(e) => handleSearchCallback(e.target.value)}
              label="Search for a Guide"
              variant="filled"
              size="small"
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: colors.swiftcaseWhite,
                borderRadius: "1rem",
                width: { md: "50%", xs: "100%" },
              }}
            />
          ) : undefined
        }
      />
    </Fragment>
  );
};

export default DocsCallToAction;
