// External Imports
import React from "react";
// Material UI
import { Typography, Box } from "@mui/material";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { IBorderedTextParagraphProps } from "../../../exports/types.exports";

/**
 * @function BorderedTextParagraph
 * @param borderColor color of the border, default websiteBlue
 * @param border border of entire box, top/bottom/right/left
 * @param paragraphs an array of string
 * @returns the bordered text component
 */
const BorderedTextParagraph = ({
  border,
  borderColor,
  paragraphs,
  fontColor,
}: IBorderedTextParagraphProps) => {
  const renderParagraphs = () => {
    const lastParagraphIndex = paragraphs.length - 1;
    return paragraphs.map((text: string, index: number) => {
      return (
        <Typography
          key={index}
          variant="body1"
          className="bordered-text-paragraph__paragraph"
          color={fontColor ?? colors.swiftcaseMidGrey}
          sx={{
            paddingLeft: "2rem",
            paddingBottom: index === lastParagraphIndex ? "0rem" : "2rem",
          }}
        >
          {text}
        </Typography>
      );
    });
  };
  return (
    <Box
      className="bordered-text-paragraph"
      sx={{
        borderLeft:
          border === "left"
            ? `0.5rem solid ${borderColor ?? colors.swiftcaseWebsiteBlue}`
            : "",
        borderRight:
          border === "right"
            ? `0.5rem solid ${borderColor ?? colors.swiftcaseWebsiteBlue}`
            : "",
        border:
          border === "all"
            ? `0.5rem solid ${borderColor ?? colors.swiftcaseWebsiteBlue}`
            : "",
        borderTop:
          border === "top"
            ? `0.5rem solid ${borderColor ?? colors.swiftcaseWebsiteBlue}`
            : "",
        borderBottom:
          border === "bottom"
            ? `0.5rem solid ${borderColor ?? colors.swiftcaseWebsiteBlue}`
            : "",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginTop: { md: "0", xs: "2rem" },
      }}
    >
      {paragraphs && renderParagraphs()}
    </Box>
  );
};

export default BorderedTextParagraph;
