// External Imports
import React from "react";
// Config
import {
  getColorScheme,
  GenerateIconListItems,
  getListIcon,
  getMarkdownText,
} from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
} from "../../../exports/components.exports";
import { Stack, Typography, List as MuiList } from "@mui/material";

/**
 * @function TwoColumnDynamicList
 * @param blockData from strapi
 * @returns the two column text list layout
 */
const TwoColumnDynamicList = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    ReverseColumnLayout,
    LeftTitle,
    LeftList,
    LeftSubtitle,
    RightTitle,
    RightList,
    RightSubtitle,
  } = blockData;

  /**
   * @function generateListItems
   * @returns the list item components for the list
   */
  const generateListItems = (listItems: Array<any>, colorScheme: string) => {
    return listItems.map((listItem: any, index: number) => {
      const { Text, Icon, MarkdownText } = listItem;
      let listText = Text !== null ? Text : getMarkdownText(MarkdownText);
      return (
        <GenerateIconListItems
          key={index}
          listItem={listText}
          index={index}
          icon={getListIcon(Icon, colorScheme)}
          fontSize="2rem"
          textColor={getColorScheme(colorScheme)?.primary}
        />
      );
    });
  };

  const rightContent = () => {
    return (
      <Stack direction="column" spacing={4}>
        {blockData?.RightTitle && (
          <Typography
            color={getColorScheme(ColorScheme)?.primary}
            className="dynamic-typography"
            variant="h2"
            textAlign="left"
          >
            {RightTitle}
          </Typography>
        )}
        {blockData?.RightSubtitle && (
          <Typography
            color={getColorScheme(ColorScheme)?.secondary}
            className="dynamic-typography"
            variant="body1"
            textAlign="left"
          >
            {RightSubtitle}
          </Typography>
        )}
        {blockData?.RightList && (
          <MuiList
            className="pricing-card__card-list"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {generateListItems(RightList, ColorScheme)}
          </MuiList>
        )}
      </Stack>
    );
  };

  const leftContent = () => {
    return (
      <Stack direction="column" spacing={4}>
        {blockData?.LeftTitle && (
          <Typography
            color={getColorScheme(ColorScheme)?.primary}
            className="dynamic-typography"
            variant="h2"
            textAlign="left"
          >
            {LeftTitle}
          </Typography>
        )}
        {blockData?.LeftSubtitle && (
          <Typography
            color={getColorScheme(ColorScheme)?.secondary}
            className="dynamic-typography"
            variant="body1"
            textAlign="left"
          >
            {LeftSubtitle}
          </Typography>
        )}
        {blockData?.LeftList && (
          <MuiList
            className="pricing-card__card-list"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {generateListItems(LeftList, ColorScheme)}
          </MuiList>
        )}
      </Stack>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default TwoColumnDynamicList;
