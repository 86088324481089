// External Imports
import React, { Fragment } from "react";
// Material UI
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Skeleton,
  CardActions,
} from "@mui/material";
import { KeyboardReturn } from "@mui/icons-material";
// Config
import { colors } from "../../exports/assets.exports";
import { ISkeletonCardProps } from "../../exports/types.exports";

/**
 * @function SkeletonCard
 * @returns the skelton card
 */
const SkeletonCard = ({
  title,
  category,
  link,
  alternateButtonText,
  alternateMessage,
  noAction = false,
}: ISkeletonCardProps) => {
  return (
    <Card sx={{ maxWidth: "35rem", marginX: "auto" }} className="skeleton-card">
      <Skeleton
        sx={{ height: "15rem", width: "100%" }}
        animation="wave"
        variant="rectangular"
      />
      <CardHeader
        title={
          alternateMessage
            ? alternateMessage
            : `No ${title}'s yet in the ${category} category.`
        }
        titleTypographyProps={{
          variant: "h3",
          color: colors.swiftcaseDarkBlue,
        }}
        sx={{ paddingX: "2rem" }}
      />
      <CardContent sx={{ paddingX: "2rem", paddingBottom: "2rem" }}>
        <Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        </Fragment>
      </CardContent>
      {!noAction && (
        <CardActions sx={{ paddingX: "2rem", paddingBottom: "2rem" }}>
          <Button
            variant="contained"
            startIcon={<KeyboardReturn />}
            href={link}
            size="small"
            sx={{
              backgroundColor: colors.swiftcaseLightBlue,
              color: colors.swiftcaseWhite,
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: colors.swiftcaseLightBlue,
                textDecoration: "underline",
                textTransform: "capitalize",
              },
            }}
          >
            {alternateButtonText ? alternateButtonText : `All ${title}'s`}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default SkeletonCard;
