// External Imports
import React, { Fragment } from "react";
// Material UI
import { Grid, Box, Typography } from "@mui/material";
import { Star } from "@mui/icons-material";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { PaddedImage } from "../exports.partials";
import { ICompanyReviewProps } from "../../../exports/types.exports";

/**
 * @function CompanyReview
 * @returns the reusable company review component for use cases
 */
const CompanyReview = ({
  image,
  review,
  reviewContact,
  reviewColor,
  reviewContactColor,
  maxImageWidth,
  invertImage,
}: ICompanyReviewProps) => {
  return (
    <Fragment>
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "center" }}
        className="company-review"
      >
        <Grid
          item
          xs={12}
          sx={{ marginY: "2rem", display: "flex", justifyContent: "center" }}
          className="company-review__grid-item"
        >
          <PaddedImage
            image={image}
            maxWidth={
              maxImageWidth ?? { lg: "60rem", md: "45rem", xs: "30rem" }
            }
            backgroundColor="transparent"
            noBorder={0}
            noShadow="none"
            invertImage={invertImage && reviewContact.includes("Gardner")}
          />
        </Grid>
        <Grid item xs={12} className="company-review__grid-item">
          <Typography
            color={reviewColor ?? colors.swiftcaseMidGrey}
            className="company-review__review"
            textAlign="center"
            variant="body1"
            sx={{ marginY: "2rem" }}
          >
            {review}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color={reviewContactColor ?? colors.swiftcaseDarkBlue}
            className="company-review__review-contact"
            textAlign="center"
            fontWeight={theme.fontWeights.swiftcaseBold}
            variant="body1"
          >
            {reviewContact}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", marginY: "2rem" }}
        >
          <Box
            className="company-review__ratings"
            sx={{
              "& > :not(style)": {
                marginX: "1rem",
              },
              color: colors.swiftcaseGold,
            }}
          >
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CompanyReview;
