// External Import
import React from "react";
// Material UI
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
} from "@mui/material";
// Config
import { colors } from "../../exports/assets.exports";
import { IMediaCardProps } from "../../exports/types.exports";
import { getMarkdownText } from "../../exports/functions.exports";

/**
 * @function MediaCard
 * @param data details to be rendered
 * @returns the media card
 */
const MediaCard = ({
  data,
  height,
  maxWidth,
  transparentBackground,
  titleColor,
  descriptionColor,
  backgroundColor,
  cardAction,
  dynamicMediaCard = false,
}: IMediaCardProps) => {
  const { description, img, title } = data;
  let formattedDescription = getMarkdownText(description);

  return (
    <Card
      className={`media-card ${
        transparentBackground ? "media-card-background" : ""
      }`}
      variant="elevation"
      elevation={transparentBackground ? 0 : 3}
      sx={{
        height: height ?? "50rem",
        width: "100%",
        maxWidth: maxWidth ?? "40rem",
        position: "relative",
        paddingBottom: "2rem",
        marginX: "auto",
        backgroundColor: transparentBackground
          ? "transparent !important"
          : backgroundColor ?? colors.swiftcaseWhite,
        minHeight: "fit-content",
      }}
    >
      {img && (
        <CardMedia
          image={img}
          component="img"
          alt={`${title} card-image`}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "20rem",
          }}
        />
      )}
      <CardHeader
        className="media-card__header"
        sx={{
          paddingX: "2rem",
        }}
        title={title}
        titleTypographyProps={{
          variant: dynamicMediaCard ? "h3" : "h4",
          color: titleColor ?? colors.swiftcaseDarkBlue,
        }}
      />
      <CardContent
        className="media-card__content"
        sx={{
          paddingTop: 0,
          paddingX: "2rem",
        }}
      >
        <Typography
          variant={dynamicMediaCard ? "body1" : "body2"}
          className="media-card__description" //text-description-line-clamp
          color={descriptionColor ?? colors.swiftcaseMidGrey}
        >
          {formattedDescription}
        </Typography>
      </CardContent>
      {cardAction && (
        <CardActions
          className="media-card__action-area"
          sx={{ paddingX: "2rem" }}
        >
          {cardAction}
        </CardActions>
      )}
    </Card>
  );
};

export default MediaCard;
