// External Imports
import React from "react";
// Config
import { colors } from "../../../exports/assets.exports";
import { getMaterialButton } from "../../../exports/functions.exports";
// Components
import { PanelWrapper } from "../../../exports/components.exports";
import { Typography, Grid } from "@mui/material";

/**
 * @function DynamicFooterOneColumn
 * @returns the simple one column layout
 */
const DynamicFooterOneColumn = ({ blockData }: { blockData: any }) => {
  const { Title, Subtitle, Buttons } = blockData;

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={"inherit"}
      contentWidth="lg"
      centered
    >
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "center" }}
        className="dynamic-footer-one-column"
        spacing={4}
      >
        {Title && (
          <Grid item xs={12} className="dynamic-footer-one-column__grid-item">
            <Typography
              color={colors.swiftcaseWhite}
              className="dynamic-footer-one-column__header"
              variant="h2"
              textAlign="center"
              sx={{ color: colors.swiftcaseWhite }}
            >
              {Title}
            </Typography>
          </Grid>
        )}
        {Subtitle && (
          <Grid item xs={12} className="dynamic-footer-one-column__grid-item">
            <Typography
              color={colors.swiftcaseWhite}
              className="dynamic-footer-one-column__header"
              variant="body1"
              textAlign="center"
              sx={{ color: colors.swiftcaseWhite }}
            >
              {Subtitle}
            </Typography>
          </Grid>
        )}
        {Buttons && (
          <Grid
            className="simple-one-column__grid-item"
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", gap: "5rem" }}
          >
            {Buttons.map((button: any) => getMaterialButton(button))}
          </Grid>
        )}
      </Grid>
    </PanelWrapper>
  );
};

export default DynamicFooterOneColumn;
