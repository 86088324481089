// External Imports
import React from "react";
// Components
import { Grid, Divider } from "@mui/material";

/**
 * @function DividerElement component
 * @returns a material ui divider within a grid item
 */
const DividerElement = () => {
  return (
    <Grid item xs={12} className="dynamic-guide__grid-item-divider">
      <Divider />
    </Grid>
  );
};

export default DividerElement;
