// External
import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import {
  DoneRounded,
  CreditCardRounded,
  ContactSupportRounded,
} from "@mui/icons-material";
// Assets
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";

/**
 *
 * @param alignment used as a value to css property justify-content in the enclosing grid container
 * @returns FreeTrialSignup component
 */
const FreeTrialSignUp = ({
  alignment,
  fullWidth,
}: {
  alignment: string;
  fullWidth?: boolean;
}) => {
  const [emailAddress, setEmailAddress] = useState("");

  return (
    <Grid
      container
      sx={{ justifyContent: alignment, zIndex: 15 }}
      className="free-trail-sign-up"
    >
      <Grid item xs={12} md={fullWidth ? 12 : 7}>
        <TextField
          onChange={(e) => setEmailAddress(e.target.value)}
          margin="normal"
          label="Enter your business email"
          size="medium"
          type="input"
          fullWidth
          variant="filled"
          sx={{
            backgroundColor: colors.swiftcaseWhite,
            minWidth: "18rem",
            ".MuiInputLabel-root": {
              marginTop: "0.4rem",
              fontSize: "1.6rem",
            },
          }}
          color="success"
        />
      </Grid>
      <Grid item md={fullWidth ? 12 : 7} xs={12}>
        <Grid
          container
          direction="row"
          spacing={2}
          className="free-trial-sign-up__button-text-grid"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item md={5} xs="auto">
            <Button
              variant="contained"
              size="medium"
              href={`${urlRoutes.CRM_SIGNUP}?emailAddress=${emailAddress}`}
              sx={{
                backgroundColor: colors.swiftcaseGreen,
                ":hover": {
                  backgroundColor: colors.swiftcaseGreen,
                  textDecoration: "underline",
                },
              }}
            >
              Start free trial
            </Button>
          </Grid>
          <Grid item xs={12} md={6.5}>
            <Grid container sx={{ justifyContent: "flex-end" }}>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color={colors.swiftcaseWhite}
                  sx={{
                    paddingBottom: "0",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <DoneRounded color="success" /> 14-day free trial
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color={colors.swiftcaseWhite}
                  sx={{
                    paddingBottom: "0",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <CreditCardRounded sx={{ marginLeft: 1 }} /> no credit card
                  required
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color={colors.swiftcaseWhite}
                  sx={{
                    paddingBottom: "0",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <ContactSupportRounded color="info" /> Help and advice from
                  our UK-based team
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FreeTrialSignUp;
