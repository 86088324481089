// External Imports
import React, {
  Fragment,
  useState,
  MouseEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { navigate } from "gatsby";
// Material UI
import {
  Box,
  Drawer,
  Button,
  List,
  ListItemButton,
  Collapse,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore, FilterList, Menu } from "@mui/icons-material";

// Config
import { colors, theme } from "../../exports/assets.exports";
import { ILinkOptionProps } from "../../exports/types.exports";

/**
 * @function DrawerSidebar
 * @param listItems and array of objects with text and href's
 * @returns the drawer sidebar component
 */
const DrawerSidebar = ({
  listItems,
  nestedList = false,
  nestedListData,
  alternateMobileButton = false,
}: {
  listItems: Array<ILinkOptionProps>;
  nestedList?: boolean;
  nestedListData?: { [key: string]: Array<ILinkOptionProps> };
  alternateMobileButton?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<any>({});

  useEffect(() => {
    let dropdownOpenObj: any = {};
    for (let i = 0; i < listItems.length; i++) {
      dropdownOpenObj[i] = false;
    }

    setDropdownOpen(dropdownOpenObj);
  }, []);

  /**
   * @function toggleDrawer
   * @returns the set state for is visible or not
   */
  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsVisible((prev) => !prev);
  };

  const handleDropdownClick = (index: number) => {
    let newDropdownOpen = dropdownOpen;

    Object.entries(newDropdownOpen).map((entry) =>
      entry[0] === index.toString()
        ? (newDropdownOpen[index] = !dropdownOpen[index])
        : (newDropdownOpen[entry[0]] = false)
    );

    setDropdownOpen({ ...newDropdownOpen });
  };

  /**
   * @function renderList
   * @returns the list of links to be displayed
   */
  const renderList = (listData: Array<ILinkOptionProps>) => (
    <Box
      sx={{
        width: "25rem",
        height: "100%",
        backgroundColor: colors.swiftcaseDarkestBlue,
        paddingLeft: nestedList ? "4rem" : "",
        zIndex: 10000000000,
      }}
      role="presentation"
      onKeyDown={toggleDrawer()}
    >
      <List className="drawer__list" sx={{ backgroundColor: "inherit" }}>
        {listData?.map(({ text, href, disabled }, index) => (
          <ListItemButton
            className={`drawer__list-item-button-${index}`}
            key={index}
            onClick={() => navigate(`${href}/`)}
            disabled={disabled}
            sx={{
              fontWeight: theme.fontWeights.swiftcaseMidweight,
              fontSize: alternateMobileButton ? "2rem" : "inherit",
              color: colors.swiftcaseWhite,
              paddingY: "1.2rem",
              ":hover": {
                backgroundColor: colors.swiftcaseDarkBlue,
              },
            }}
          >
            {text}
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  /**
   * @function renderNestedList
   * @returns the list of links to be displayed
   */
  const renderNestedList = (listData: Array<ILinkOptionProps>) => {
    return (
      <Box
        sx={{
          width: "30rem",
          height: "100%",
          backgroundColor: colors.swiftcaseDarkestBlue,
        }}
        role="presentation"
        onKeyDown={toggleDrawer()}
      >
        <List className="drawer__list" sx={{ backgroundColor: "inherit" }}>
          {listData?.map(({ text, href, disabled }, index) => (
            <Fragment key={index}>
              <ListItem
                className={`drawer__list-item-button-${index}`}
                key={index}
                onClick={() =>
                  disabled ? handleDropdownClick(index) : navigate(`${href}/`)
                }
                sx={{
                  ":hover": {
                    cursor: "pointer",
                    backgroundColor: colors.swiftcaseDarkBlue,
                  },
                }}
                secondaryAction={
                  nestedListData && nestedListData[text]?.length > 0 ? (
                    dropdownOpen[index] ? (
                      <ExpandLess sx={{ color: colors.swiftcaseWhite }} />
                    ) : (
                      <ExpandMore sx={{ color: colors.swiftcaseWhite }} />
                    )
                  ) : undefined
                }
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    variant: alternateMobileButton ? "body1" : "h5",
                    color: colors.swiftcaseWhite,
                  }}
                  sx={{
                    width: "fit-content",
                    ".MuiListItemText-primary": {
                      width: "fit-content",
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    },
                  }}
                />
              </ListItem>
              {nestedListData && (
                <Collapse in={dropdownOpen[index]} timeout="auto" unmountOnExit>
                  {renderList(nestedListData[text])}
                </Collapse>
              )}
            </Fragment>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Fragment>
      {alternateMobileButton ? (
        <IconButton
          size="large"
          color="inherit"
          onClick={toggleDrawer()}
          className="mobile-navbar__icon"
        >
          <Menu />
        </IconButton>
      ) : (
        <Button
          size="small"
          variant="contained"
          endIcon={<FilterList />}
          sx={{
            backgroundColor: colors.swiftcaseLightBlue,
            color: colors.swiftcaseWhite,
            ":hover": {
              backgroundColor: colors.swiftcaseLightBlue,
              color: colors.swiftcaseWhite,
              textTransform: "underline",
            },
          }}
          onClick={toggleDrawer()}
          className="drawer__button"
        >
          Categories
        </Button>
      )}
      <Drawer
        anchor="left"
        open={isVisible}
        onClose={toggleDrawer()}
        sx={{ height: "100vh" }}
        className="drawer"
      >
        {!nestedList && renderList(listItems)}
        {nestedList && renderNestedList(listItems)}
      </Drawer>
    </Fragment>
  );
};

export default DrawerSidebar;
