// External Imports
import React from "react";
import { Grid, Typography } from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import {
  getColorScheme,
  getImageSize,
  getMaterialButton,
  getMarkdownText,
} from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
} from "../../../exports/components.exports";

/**
 * @function DynamicFooterTwoColumn
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const DynamicFooterTwoColumn = ({ blockData }: { blockData: any }) => {
  const { ReverseColumnLayout } = blockData;

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getButtonPosition
   * @param position string position with value right|centre|left
   * @returns the justify content value for placement
   */
  const getButtonPosition = (position: string) => {
    switch (position) {
      case "right":
        return "flex-end";
      case "center":
        return "center";
      case "left":
        return "flex-start";
      default:
        return "flex-start";
    }
  };

  const rightContent = () => {
    return (
      <Grid container spacing={4} className="complex-two-column__right">
        {blockData?.RightTitle && (
          <Grid item xs={12}>
            {getTypography("h2", blockData?.RightTitle, colors.swiftcaseWhite)}
          </Grid>
        )}
        {blockData?.RightSubtitle && (
          <Grid item xs={12}>
            {getTypography(
              "body1",
              blockData?.RightSubtitle,
              colors.swiftcaseWhite
            )}
          </Grid>
        )}
        {blockData?.RightButton && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: getButtonPosition(
                blockData?.RightButtonHorizontalPosition
              ),
            }}
          >
            {getMaterialButton(blockData?.RightButton)}
          </Grid>
        )}
      </Grid>
    );
  };

  const leftContent = () => {
    return (
      <Grid container spacing={4} className="complex-two-column__left">
        {blockData?.LeftTitle && (
          <Grid item xs={12}>
            {getTypography("h2", blockData?.LeftTitle, colors.swiftcaseWhite)}
          </Grid>
        )}
        {blockData?.LeftSubtitle && (
          <Grid item xs={12}>
            {getTypography(
              "body1",
              blockData?.LeftSubtitle,
              colors.swiftcaseWhite
            )}
          </Grid>
        )}
        {blockData?.LeftButton && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: getButtonPosition(
                blockData?.LeftButtonHorizontalPosition
              ),
            }}
          >
            {getMaterialButton(blockData?.LeftButton)}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={"inherit"}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default DynamicFooterTwoColumn;
