// External Imports
import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
// Components
import { DropdownButton } from "../../../exports/components.exports";
// Images
import { SwiftcaseLogo, colors } from "../../../exports/assets.exports";
// Material UI
import { Link, Box, Button } from "@mui/material";
// Constants
import { urlRoutes } from "../../../exports/constants.exports";
// Config
import { getButtonColor } from "../../../exports/functions.exports";
import { IDropdownLink } from "exports/types.exports";

/**
 * Query to get the Data from strapiNavbar
 */
const query = graphql`
  query {
    strapiNavbar {
      Menu
    }
  }
`;

/**
 * @function useDesktopNavbar -> a custom hook for generating the desktop navbar
 * @returns the functions for generating the desktop view of the navbar
 */
export const useDesktopNavbar = () => {
  const {
    strapiNavbar: { Menu },
  } = useStaticQuery(query);

  /**
   * @function desktopNavbarTrialButton
   * @returns the start trial button for Desktop View
   */
  const desktopNavbarTrialButton = () => {
    let navbarButton = Menu.filter(
      (item: any) => item.strapi_component === "shared.button"
    );

    return (
      <Fragment>
        <Box sx={{ flexGrow: 0 }} className="desktop-navbar__button-wrapper">
          {navbarButton.map((button: any) => {
            const { ButtonText, ButtonColor, ButtonLink, ButtonVariant } =
              button;

            return (
              <Button
                key={ButtonText}
                className="desktop-navbar__button"
                variant={ButtonVariant}
                size="small"
                href={ButtonLink}
                sx={{
                  height: "100%",
                  display: { xs: "none", lg: "block" },
                  backgroundColor: getButtonColor(ButtonColor),
                  fontSize: "1.6rem",
                  ":hover": {
                    backgroundColor: getButtonColor(ButtonColor),
                    textDecoration: "underline",
                  },
                }}
              >
                {ButtonText}
              </Button>
            );
          })}
        </Box>
      </Fragment>
    );
  };

  /**
   * @function singleDynamicLink
   * @returns the dynamically created single link from strapi data
   */
  const singleDynamicLink = (link: any, key: number) => {
    const { LinkHref, LinkText } = link;

    return (
      <Button
        key={key}
        className="desktop-navbar__menu-button"
        href={LinkHref}
        sx={{
          my: 2,
          color: colors.swiftcaseWhite,
          display: "block",
          fontSize: "inherit",
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: "inherit",
            color: "inherit",
            textDecoration: "underline",
          },
        }}
      >
        {LinkText}
      </Button>
    );
  };

  /**
   * @function dynamicDropdown
   * @returns the dynamically created dropdown from strapi data
   */
  const dynamicDropdown = (dropdown: any, key: number) => {
    const { DropdownTitle, Link } = dropdown;
    let DropdownOptions: Array<IDropdownLink> = [];
    Link.forEach((link: any) =>
      DropdownOptions.push({ text: link.LinkText, href: link.LinkHref })
    );
    return (
      <DropdownButton
        key={key}
        buttonText={DropdownTitle}
        options={DropdownOptions}
      />
    );
  };

  /**
   * @function getNavbarLinkAndDropdown
   * @param navbarLinkAndDropdown array of data from strapi filtered for link or dropdown elements only
   * @returns the single link element or a dropdown
   */
  const getNavbarLinkAndDropdown = (navbarLinkAndDropdown: Array<any>) => {
    return navbarLinkAndDropdown.map((linkOrDropdown: any, index: number) => {
      return linkOrDropdown.strapi_component === "shared.link"
        ? singleDynamicLink(linkOrDropdown, index)
        : dynamicDropdown(linkOrDropdown, index);
    });
  };

  /**
   * @function desktopNavbarMenu
   * @returns the menu options for Desktop View
   */
  const desktopNavbarMenu = () => {
    let navbarLinkAndDropdown = Menu.filter(
      (item: any) =>
        item.strapi_component === "shared.link" ||
        item.strapi_component === "shared.dropdown"
    );

    return (
      <Fragment>
        <Box
          className="desktop-navbar__menu"
          sx={{
            flexGrow: 1,
            display: { xs: "none", lg: "flex" },
            columnGap: 3,
            fontSize: "1.6rem",
            marginX: "3rem",
            justifyContent: "flex-end",
          }}
        >
          {navbarLinkAndDropdown &&
            getNavbarLinkAndDropdown(navbarLinkAndDropdown)}
        </Box>
      </Fragment>
    );
  };

  /**
   * @function desktopNavbarLogo
   * @returns the logo for Desktop View
   */
  const desktopNavbarLogo = () => {
    return (
      <Fragment>
        <Box
          className="desktop-navbar__logo-wrapper"
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            maxHeight: "12rem",
            maxWidth: { lg: "12rem", md: "10rem" },
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Link
            href={urlRoutes.HOMEPAGE}
            underline="none"
            className="desktop-navbar__logo-link"
          >
            <img
              className="desktop-navbar__logo"
              src={SwiftcaseLogo}
              loading="eager"
              alt="SwiftCase Logo"
              style={{
                padding: 0,
                marginTop: "0.5rem",
                width: "100%",
                height: "100%",
              }}
            />
          </Link>
        </Box>
      </Fragment>
    );
  };
  return { desktopNavbarTrialButton, desktopNavbarMenu, desktopNavbarLogo };
};
