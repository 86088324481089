// External Imports
import React, { Fragment, useEffect } from "react";
// Material UI
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
// Config
import { theme, colors } from "../exports/assets.exports";
import { ILayoutProps } from "../exports/types.exports";
import "../assets/scss/styles.scss";
// Components
import { NavigationBar, WebsiteFooter } from "../exports/components.exports";

/**
 * Layout for the different routes, docs, articles, categories are separate
 */
export default ({ children }: ILayoutProps) => {
  useEffect(() => {
    const hubspot = document.createElement("script");

    hubspot.type = "text/javascript";
    hubspot.id = "hs-script-loader";
    hubspot.async = true;
    hubspot.defer = true;
    hubspot.src = "//js-na1.hs-scripts.com/5303895.js";

    document.body.appendChild(hubspot);

    return () => {
      // Remove scripts from DOM
      document.body.removeChild(hubspot);
    };
  }, []);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        {children ? (
          <Box className="website-layout-wrapper">
            <NavigationBar />
            <Box className="website-children-wrapper">{children}</Box>
            <WebsiteFooter />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.swiftcaseBackgroundBlue,
            }}
          >
            <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
          </Box>
        )}
      </ThemeProvider>
    </Fragment>
  );
};
