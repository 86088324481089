// External Imports
import React, { Fragment, ReactElement } from "react";
// Material UI
import {
  AppBar,
  Toolbar,
  Container,
  useScrollTrigger,
  Slide,
  Box,
} from "@mui/material";
// Partials
import {
  useMobileNavbar,
  useDesktopNavbar,
} from "../../../exports/components.exports";
// Assets
import { colors } from "../../../exports/assets.exports";

/**
 * @function NavigationBar component
 * @returns the navigation bar for the website
 */
const NavigationBar = () => {
  const { mobileNavMenu, mobileNavLogo } = useMobileNavbar();
  const { desktopNavbarTrialButton, desktopNavbarMenu, desktopNavbarLogo } =
    useDesktopNavbar();

  /**
   * @function HideOnScroll
   * @param children the children that this component wraps
   * @returns the hide on scroll component
   */
  const HideOnScroll = ({ children }: { children: ReactElement }) => {
    const trigger = useScrollTrigger({
      threshold: 10,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  return (
    <Fragment>
      <Box
        height={{ md: "7rem", xs: "5rem" }}
        sx={{ backgroundColor: colors.swiftcaseDarkestBlue }}
        className="website-navigation-bar__container"
      >
        <HideOnScroll>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: colors.swiftcaseDarkestBlue,
              justifyContent: "center",
              height: "8rem",
            }}
            className="navigation-bar"
          >
            <Container maxWidth="xl" className="navigation-bar__container">
              <Toolbar disableGutters className="navigation-bar__toolbar">
                {/* Desktop View */}
                {desktopNavbarLogo()}

                {/* Mobile View */}
                {mobileNavMenu()}
                {/* Mobile View */}
                {mobileNavLogo()}

                {/* Desktop View */}
                {desktopNavbarMenu()}
                {desktopNavbarTrialButton()}
              </Toolbar>
            </Container>
          </AppBar>
        </HideOnScroll>
      </Box>
    </Fragment>
  );
};

export default NavigationBar;
