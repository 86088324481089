// External Imports
import React from "react";
import { Grid, Typography } from "@mui/material";
// Config
import {
  getColorScheme,
  getMarkdownText,
} from "../../../exports/functions.exports";
import { HeaderBackground } from "../../../exports/assets.exports";
// Components
import { PanelWrapper } from "../../../exports/components.exports";

/**
 * @function TextOnlyNumberColumns
 * @param blockData from strapi
 * @returns the text only number of column component
 */
const TextOnlyNumberColumns = ({ blockData }: { blockData: any }) => {
  let paragraphs: Array<string | any> = [];
  const {
    ColorScheme,
    Title,
    ColumnText,
    NumberColumns,
    UseDotBackgroundPattern,
  } = blockData;

  ColumnText.map((text: any) => {
    if (text.Paragraph === null || text.Paragraph === "") {
      paragraphs.push(getMarkdownText(text.MarkdownParagraph));
    } else {
      paragraphs.push(text.Paragraph);
    }
  });

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getSimpleRepeatedTypography
   * @param paragraphs an array of strings
   * @param mediumBreakpoint used for when multiple columns are required
   * @returns a grid item and typography element for each string in the array, of body text, and secondary color scheme
   */
  const getSimpleRepeatedTypography = (
    paragraphs: Array<string>,
    mediumBreakpoint?: number
  ) => {
    return paragraphs.map((text: string, index: number) => {
      return (
        <Grid item md={mediumBreakpoint} xs={12} key={index}>
          {getTypography("body1", text, getColorScheme(ColorScheme)?.secondary)}
        </Grid>
      );
    });
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      backgroundImage={UseDotBackgroundPattern ? HeaderBackground : undefined}
      backgroundPosition="right"
      backgroundSize="cover"
      contentWidth="lg"
      centered
    >
      <Grid className="text-only-number-columns" container spacing={4}>
        <Grid item xs={12}>
          {getTypography("h2", Title, getColorScheme(ColorScheme)?.primary)}
        </Grid>
        {NumberColumns === "one" && getSimpleRepeatedTypography(paragraphs)}
        {NumberColumns === "two" && getSimpleRepeatedTypography(paragraphs, 6)}
        {NumberColumns === "three" &&
          getSimpleRepeatedTypography(paragraphs, 4)}
      </Grid>
    </PanelWrapper>
  );
};

export default TextOnlyNumberColumns;
