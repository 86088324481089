// External Imports
import React, { Fragment } from "react";
// Config
import { IGenerateIconListItemsProps } from "../../exports/types.exports";
// Material UI
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";

/**
 * @function GenerateIconListItems
 * @returns the list item components for the list
 */
const GenerateIconListItems = ({
  listItem,
  icon,
  index,
  fontSize,
  textColor,
  disableGutters,
}: IGenerateIconListItemsProps) => {
  return (
    <Fragment>
      <ListItem
        key={index}
        disableGutters={disableGutters}
        sx={{ width: "fit-content" }}
        className="reusable-ist-item"
      >
        <ListItemIcon className="reusable-ist-item__icon">{icon}</ListItemIcon>
        <ListItemText
          className="reusable-ist-item__text"
          primaryTypographyProps={{
            fontSize: fontSize ?? "1.6rem",
            color: textColor ?? undefined,
          }}
          primary={listItem}
        />
      </ListItem>
    </Fragment>
  );
};

export default GenerateIconListItems;
