// External Imports
import React from "react";
// Components
import { PaddedImage } from "../../../exports/components.exports";
import { Grid } from "@mui/material";

/**
 * @function ImageElement component
 * @param imageData image data for the strapi component
 * @returns the padded image element within a grid item, centered horizontally, size set by strapi
 */
const ImageElement = ({ imageData }: { imageData: any }) => {
  const { Image, ImageSize } = imageData;

  const getImageSize = (imageSize: string) => {
    switch (imageSize) {
      case "small":
        return "50%";
      case "medium":
        return "70%";
      case "large":
        return "90%";
      default:
        return "75%";
    }
  };

  return (
    <Grid
      item
      xs={12}
      className="dynamic-guide__grid-item"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <PaddedImage
        useZoom
        image={Image.url}
        maxWidth={getImageSize(ImageSize)}
      />
    </Grid>
  );
};

export default ImageElement;
