// External Imports
import React, { Fragment, useState, MouseEvent } from "react";
// Material UI
import { Link, Button, Menu, MenuItem, Fade } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IDropdownButtonProps } from "../../exports/types.exports";

/**
 * @function DropdownButton
 * @param buttonText the text to be displayed in the button
 * @param options an array of links for the dropdown
 * @returns the component
 */
const DropdownButton = ({
  buttonText,
  options,
  textColor,
  backgroundColor,
  buttonVariant,
  mainButtonIcon,
}: IDropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuLinks = () => {
    return options.map((option, index) => {
      return (
        <MenuItem
          key={index}
          className="dropdown-button__menu-item"
          disableGutters
          sx={{
            ":hover": {
              backgroundColor: "transparent",
              cursor: "auto",
            },
          }}
        >
          <Button
            className="dropdown-button__link-button"
            href={option.href}
            color="inherit"
            sx={{
              fontSize: "1.5rem",
              width: "100%",
              justifyContent: "flex-start",
              textTransform: "capitalize",
              paddingX: "2.4rem",
            }}
          >
            {option.text}
          </Button>
        </MenuItem>
      );
    });
  };

  return (
    <Fragment>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={buttonVariant ?? "text"}
        disableElevation
        onClick={handleClick}
        endIcon={mainButtonIcon ?? <KeyboardArrowDownIcon />}
        className="dropdown-button_main-button"
        sx={{
          backgroundColor: backgroundColor ?? "inherit",
          color: textColor ?? "inherit",
          fontSize: "inherit",
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: backgroundColor ?? "inherit",
          },
        }}
        size="small"
      >
        {buttonText}
      </Button>
      <Menu
        id="fade-menu"
        className="dropdown-button__menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        elevation={5}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderMenuLinks()}
      </Menu>
    </Fragment>
  );
};

export default DropdownButton;
