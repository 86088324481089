// External
import React, { Fragment, useState } from "react";
// Material UI
import {
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import { IResponse, ITaskKeyValuePair } from "../../../exports/types.exports";
import { createTask } from "../../../exports/functions.exports";
import { SnackbarFeedback } from "../../../exports/components.exports";

/**
 * @function ContactUsForm
 * @returns the contact us form
 */
const ContactUsForm = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [isSubmissionError, setIsSubmissionError] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  /**
   * resets state of form fields
   */
  const resetForm = () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");
  };

  /**
   * @function getFormFields
   * @returns List of fields tied to controls to manage the form conents
   */
  const getFormFields = () => {
    return (
      <Fragment>
        <TextField
          onKeyUp={validateForm}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          label="Name"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          label="Email"
          required
          sx={{ margin: "1rem 0" }}
        />
        <TextField
          onKeyUp={validateForm}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone number"
          label="Phone number"
          required
          sx={{ margin: "1rem 0" }}
        />
        <TextField
          onKeyUp={validateForm}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Message content"
          label="Message content"
          multiline
          required
          sx={{ margin: "1rem 0" }}
        />
      </Fragment>
    );
  };

  /**
   * @function getButton
   * @returns Relevant button based on form submission state
   */
  const getButton = () => {
    return isSubmissionSuccess ? (
      <Button
        disabled
        variant="contained"
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
          backgroundColor: colors.swiftcaseGreen,
          ":hover": {
            backgroundColor: colors.swiftcaseGreen,
            textDecoration: "underline",
          },
        }}
      >
        Success
      </Button>
    ) : isProcessingSubmission ? (
      <Button
        disabled
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
        }}
      >
        <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
      </Button>
    ) : (
      <Button
        onClick={performContactRequest}
        variant="contained"
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
          backgroundColor: !formHasError
            ? colors.swiftcaseMediumBlue
            : colors.swiftcaseLightPink,
          ":hover": {
            textDecoration: "underline",
            backgroundColor: !formHasError
              ? colors.swiftcaseMediumBlue
              : colors.swiftcaseLightPink,
          },
        }}
      >
        {!formHasError ? "Submit" : "Please fill all fields"}
      </Button>
    );
  };

  const validateForm = () => {
    let hasError = false;

    if (name !== "" && email !== "" && phoneNumber !== "" && message !== "") {
      if (formHasError) {
        setFormHasError(hasError);
      }
    } else {
      hasError = true;
      setFormHasError(hasError);
    }

    return hasError;
  };

  /**
   * Builds request body and calls service method "Create Task" to create Contact Us task, handles redirect
   */
  const performContactRequest = async () => {
    if (!validateForm()) {
      let response: IResponse = { isAccepted: false, message: "" };
      setIsProcessingSubmission(true);

      const taskData: Array<ITaskKeyValuePair> = [
        {
          name: "name_1",
          value: `${name}`,
        },
        {
          name: "client_email_address",
          value: `${email}`,
        },
        {
          name: "phone_number",
          value: `${phoneNumber}`,
        },
        {
          name: "content",
          value: `${message}`,
        },
      ];

      response = await createTask(
        process.env.REACT_APP_CONTACT_US_WORKFLOW_ID!,
        taskData,
        response
      );

      setIsProcessingSubmission(false);
      resetForm();

      if (response.isAccepted) {
        setIsSubmissionSuccess(true);
      } else {
        setIsSubmissionError(false);
      }
    }
  };

  return (
    <Fragment>
      <Grid container height="100%" className="contact-us-form__container">
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color={colors.swiftcaseDarkBlue}
            sx={{ marginBottom: "2rem" }}
          >
            {title ?? "Contact us"}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              color={colors.swiftcaseDarkBlue}
              sx={{ marginBottom: "2rem" }}
            >
              {description}
            </Typography>
          )}
          <form className="swiftcase-contact-form">
            {getFormFields()}
            {getButton()}
          </form>
        </Grid>
      </Grid>
      {isSubmissionSuccess && (
        <SnackbarFeedback
          alertType="success"
          alertMessage="Thank you. Your request has been successfully sent."
        />
      )}
      {isSubmissionError && (
        <SnackbarFeedback
          alertType="error"
          alertMessage="We're sorry. There was a problem submitting your request, please try again."
        />
      )}
    </Fragment>
  );
};

export default ContactUsForm;
