// External Imports
import React from "react";
// Config
import {
  getColorScheme,
  getImageSize,
} from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  TextPanelTitleSubtitle,
  PaddedImage,
} from "../../../exports/components.exports";

/**
 * @function TwoColumnTitleSubtitleImage
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const TwoColumnTitleSubtitleImage = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    ReverseColumnLayout,
    Subtitle,
    Title,
    Image,
    ImageSize,
  } = blockData;

  const rightContent = () => {
    return (
      <PaddedImage
        image={Image.url}
        maxWidth={getImageSize(ImageSize)}
        noBorder={0}
        noShadow="none"
        backgroundColor="transparent"
      />
    );
  };

  const leftContent = () => {
    return (
      <TextPanelTitleSubtitle
        title={Title}
        subTitle={Subtitle}
        titleColor={getColorScheme(ColorScheme)?.primary}
        subTitleColor={getColorScheme(ColorScheme)?.secondary}
      />
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement={ReverseColumnLayout ? "center" : "top"}
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement={ReverseColumnLayout ? "top" : "center"}
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default TwoColumnTitleSubtitleImage;
