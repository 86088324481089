// External Imports
import React from "react";
import { Grid, Typography } from "@mui/material";
// Config
import { getColorScheme } from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  ThreeRecentBlogs,
} from "../../../exports/components.exports";

/**
 * @function RecentBlogs
 * @param blockData from strapi
 * @returns the three recent blogs
 */
const RecentBlogs = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, Title } = blockData;

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" color={getColorScheme(ColorScheme)?.primary}>
            {Title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ThreeRecentBlogs />
        </Grid>
      </Grid>
    </PanelWrapper>
  );
};

export default RecentBlogs;
