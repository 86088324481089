// External Imports
import React, { useState, useEffect } from "react";
// Config
import { colors } from "../../../exports/assets.exports";
import { getColorScheme } from "../../../exports/functions.exports";
import { IPricingCardData } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
// Components
import {
  PanelWrapper,
  PricingCardGridItem,
} from "../../../exports/components.exports";
import { Grid } from "@mui/material";

/**
 * @function PricingCardGrid
 * @returns the dynamic media card grid
 */
const PricingCardGrid = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, PricingCard } = blockData;
  const [formattedCardData, setFormattedCardData] = useState<
    Array<IPricingCardData>
  >([]);

  useEffect(() => {
    let tmpCardData: Array<IPricingCardData> = [];

    const getButton = (button: string) => {
      switch (button) {
        case "getStarted":
          return {
            text: "Get Started",
            link: urlRoutes.CRM_SIGNUP,
          };
        case "comingSoon":
          return {
            text: "Coming Soon",
            link: "",
          };
        case "contactUs":
          return {
            text: "Contact Us",
            link: urlRoutes.CONTACT,
          };

        default:
          return {
            text: "Get Started",
            link: urlRoutes.CRM_SIGNUP,
          };
      }
    };

    PricingCard.map((card: any) => {
      tmpCardData.push({
        title: card.Title,
        subtitle: card.Subtitle,
        price: card.Price,
        priceSubtitle: card.PriceNote,
        listItems: card.PriceInfo.split("//" || " // "),
        color: card.CardColor,
        notes: card.PriceNote,
        buttonText: getButton(card.Button).text,
        buttonLink: getButton(card.Button).link,
      });
    });

    setFormattedCardData(tmpCardData);
  }, []);

  const RenderPricingCard = ({
    cardData,
  }: {
    cardData: Array<IPricingCardData>;
  }) => {
    /**
     * @function renderGridItemCard
     * @returns the react component partial of the grid item and card
     */
    const renderGridItemCard = () => {
      return cardData.map((cardData: IPricingCardData, index: number) => {
        return <PricingCardGridItem key={index} cardData={cardData} />;
      });
    };

    return (
      <Grid
        container
        sx={{ justifyContent: "space-between" }}
        className="pricing-panel-one__grid-container"
        spacing={4}
      >
        {renderGridItemCard()}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "center" }}
        className="simple-one-column"
        spacing={4}
      >
        <Grid item xs={12} className="pricing-panel-one__grid-item">
          {formattedCardData && (
            <RenderPricingCard cardData={formattedCardData} />
          )}
        </Grid>
      </Grid>
    </PanelWrapper>
  );
};

export default PricingCardGrid;
