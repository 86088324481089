// External Imports
import React from "react";
import {
  CalendarMonth,
  Done,
  Email,
  Folder,
  FormatListNumbered,
  Person,
  Remove,
  Settings,
  Timer,
} from "@mui/icons-material";
// Config
import { getColorScheme } from "../../../exports/functions.exports";

/**
 * @function getListIcon
 * @param icon the string of the icon to render
 * @param colorScheme the color to be applied to it
 * @returns the icon for the list
 */
const getListIcon = (icon: string, colorScheme: string) => {
  switch (icon) {
    case "minus":
      return <Remove sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "tick":
      return <Done sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "user":
      return <Person sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "email":
      return <Email sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "calendar":
      return (
        <CalendarMonth sx={{ color: getColorScheme(colorScheme)?.primary }} />
      );
    case "settings":
      return <Settings sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "folder":
      return <Folder sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    case "list":
      return (
        <FormatListNumbered
          sx={{ color: getColorScheme(colorScheme)?.primary }}
        />
      );
    case "timer":
      return <Timer sx={{ color: getColorScheme(colorScheme)?.primary }} />;
    default:
      return <Remove sx={{ color: getColorScheme(colorScheme)?.primary }} />;
  }
};

export default getListIcon;
