// External
import { Box, Container, Divider, Grid, Typography, Link } from "@mui/material";
import React, { Fragment } from "react";
// Config
import {
  colors,
  CyberEssentials,
  LivepointSWCALogos,
} from "../../../exports/assets.exports";
// Constants
import {
  CompanyCopyright,
  CompanyRegesteredIn,
  CompanyVatRegistrationNumber,
  urlRoutes,
} from "../../../exports/constants.exports";

const LivepointFooter = () => {
  const getRegisteredCompanyData = () => {
    const privacyPolicyLink = () => {
      return (
        <Link
          href={urlRoutes.PRIVACY_POLICY}
          color={colors.swiftcaseLinkBlue}
          sx={{
            ":hover": {
              color: colors.swiftcaseWhite,
            },
          }}
        >
          Privacy Policy & Data Protection
        </Link>
      );
    };
    return (
      <Fragment>
        {CompanyCopyright} | {CompanyRegesteredIn} |{" "}
        {CompanyVatRegistrationNumber} | {privacyPolicyLink()}
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Container
        className="livepoint-footer"
        disableGutters={true}
        maxWidth={false}
        sx={{
          width: "100%",
          margin: 0,
          paddingBottom: { md: "6rem", xs: "12rem" },
          padding: 0,
          zIndex: 30,
          position: "relative",
          backgroundColor: "inherit",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "0.75rem",
          }}
        >
          <Grid
            container
            width="80%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              xs={6}
              className="livepoint-footer__images"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={LivepointSWCALogos}
                alt="Livepoint Swiftcase Logos"
                style={{ height: "3rem", width: "18rem" }}
                loading="lazy"
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="livepoint-footer__images"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={CyberEssentials}
                alt="Cyber Essentials Logo"
                style={{ height: "6rem", width: "5rem" }}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            height: "0.2rem",
            backgroundColor: colors.swiftcaseNeonGreen,
            maxHeight: "0.5rem",
          }}
        ></Divider>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Grid
            container
            width="80%"
            display="flex"
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography
              variant="subtitle1"
              marginTop="2rem"
              color={colors.swiftcaseWhite}
            >
              {getRegisteredCompanyData()}
            </Typography>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default LivepointFooter;
