// External Imports
import React from "react";
// Config
import { colors, theme } from "../../../exports/assets.exports";
// Components
import { Grid, Typography } from "@mui/material";

/**
 * @function HeaderText component
 * @param headerText data from strapi for this component
 * @returns the header text, either a header/subHeader/nestedSubHeader equivalent to h3/h4/h5
 */
const HeaderText = ({ headerText }: { headerText: any }) => {
  const {
    Variant,
    Text,
  }: {
    Variant: "header_h2" | "subHeader_h3" | "nestedSubHeader_h4";
    Text: string;
  } = headerText;

  /**
   * @function getVariant
   * @returns the variant equivalent for the strapi setup
   *
   * the reason for the difference in terms of variant to what is actually set is due to the h1 for the page being inside the call to action and the h2 being the title
   */
  const getVariant = (variant: string) => {
    switch (variant) {
      case "header_h2":
        return "h3";
      case "subHeader_h3":
        return "h4";
      case "nestedSubHeader_h4":
        return "h5";
      default:
        return "h3";
    }
  };

  return (
    <Grid
      item
      xs={12}
      className="dynamic-guide__grid-item--header-text"
      sx={{ marginTop: "3rem" }}
    >
      <Typography
        variant={getVariant(Variant)}
        className="dynamic-guide__header-text"
        color={colors.swiftcaseDarkBlue}
        sx={{ fontSize: getVariant(Variant) === "h5" ? "2rem" : undefined }}
        fontWeight={theme.fontWeights.swiftcaseSemiBold}
        id={Text}
      >
        {Text}
      </Typography>
    </Grid>
  );
};

export default HeaderText;
