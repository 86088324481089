// External
import React, { Fragment, useState } from "react";
// Material UI
import {
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import { IResponse, ITaskKeyValuePair } from "../../../exports/types.exports";
import { createTask } from "../../../exports/functions.exports";
import { SnackbarFeedback } from "../../../exports/components.exports";

const SupportRequestForm = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [topic, setTopic] = useState("");
  const [summary, setSummary] = useState("");
  const [details, setDetails] = useState("");
  const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [isSubmissionError, setIsSubmissionError] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  /**
   * resets state of form fields
   */
  const resetForm = () => {
    setFirstName("");
    setSurname("");
    setEmailAddress("");
    setPhoneNumber("");
    setTopic("");
    setSummary("");
    setDetails("");
  };

  /**
   *
   * @returns List of fields tied to controls to manage the form conents
   */
  const getFormFields = () => {
    return (
      <Fragment>
        <TextField
          onKeyUp={validateForm}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          label="First Name"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          placeholder="Surname"
          label="Surname"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          placeholder="Email Address"
          label="Email Address"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          label="Phone Number"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onBlur={validateForm}
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Topic"
          label="Topic"
          select
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
            color: colors.swiftcaseBlue,
          }}
        >
          <MenuItem value="bugReport">I'm reporting a bug</MenuItem>
          <MenuItem value="troubleLoggingIn">
            I'm having trouble logging in
          </MenuItem>
          <MenuItem value="troubleSigningUp">
            I'm having trouble signing up
          </MenuItem>
          <MenuItem value="featureRequest">I have a feature request</MenuItem>
          <MenuItem value="assistanceRequest">
            I need some assistance using the system
          </MenuItem>
          <MenuItem value="other">Something else</MenuItem>
        </TextField>
        <TextField
          onKeyUp={validateForm}
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Summary"
          label="Summary"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          placeholder="Details"
          label="Details"
          multiline
          required
          sx={{ margin: "1rem 0" }}
        />
      </Fragment>
    );
  };

  /**
   *
   * @returns Relevant button based on form submission state
   */
  const getButton = () => {
    return isSubmissionSuccess ? (
      <Button
        disabled
        variant="contained"
        color="success"
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
          backgroundColor: colors.swiftcaseGreen,
          ":hover": {
            backgroundColor: colors.swiftcaseGreen,
            textDecoration: "underline",
          },
        }}
      >
        Success
      </Button>
    ) : isProcessingSubmission ? (
      <Button
        disabled
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
        }}
      >
        <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
      </Button>
    ) : (
      <Button
        onClick={performSupportRequest}
        variant="contained"
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
          backgroundColor: !formHasError
            ? colors.swiftcaseMediumBlue
            : colors.swiftcaseLightPink,
          ":hover": {
            textDecoration: "underline",
            backgroundColor: !formHasError
              ? colors.swiftcaseMediumBlue
              : colors.swiftcaseLightPink,
          },
        }}
      >
        {!formHasError ? "Submit" : "Please fill all fields"}
      </Button>
    );
  };

  const validateForm = () => {
    let hasError = false;

    if (
      firstName !== "" &&
      surname !== "" &&
      emailAddress !== "" &&
      phoneNumber !== "" &&
      topic !== "" &&
      summary !== "" &&
      details !== ""
    ) {
      if (formHasError) {
        setFormHasError(hasError);
      }
    } else {
      hasError = true;
      setFormHasError(hasError);
    }

    return hasError;
  };

  /**
   * Builds request body and calls service method "Create Task" to create Support Request task, handles redirect
   */
  const performSupportRequest = async () => {
    if (!validateForm()) {
      let response: IResponse = { isAccepted: false, message: "" };
      setIsProcessingSubmission(true);

      const taskData: Array<ITaskKeyValuePair> = [
        {
          name: "first_name",
          value: `${firstName}`,
        },
        {
          name: "surname",
          value: `${surname}`,
        },
        {
          name: "client_email_address",
          value: `${emailAddress}`,
        },
        {
          name: "phone_number",
          value: `${phoneNumber}`,
        },
        {
          name: "website_support_request_topic",
          value: `${topic}`,
        },
        {
          name: "summary",
          value: `${summary}`,
        },
        {
          name: "details",
          value: `${details}`,
        },
      ];

      response = await createTask(
        process.env.REACT_APP_SUPPORT_REQUEST_WORKFLOW_ID!,
        taskData,
        response
      );

      setIsProcessingSubmission(false);
      resetForm();

      if (response.isAccepted) {
        setIsSubmissionSuccess(true);
      } else {
        setIsSubmissionError(true);
      }
    }
  };

  return (
    <Fragment>
      <Grid container height="100%" className="support-request-from">
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color={colors.swiftcaseDarkBlue}
            sx={{ marginBottom: "2rem" }}
          >
            {title ?? "Submit a support request"}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              color={colors.swiftcaseDarkBlue}
              sx={{ marginBottom: "2rem" }}
            >
              {description}
            </Typography>
          )}
          <form className="swiftcase-contact-form">
            {getFormFields()}
            {getButton()}
          </form>
        </Grid>
      </Grid>
      {isSubmissionSuccess && (
        <SnackbarFeedback
          alertType="success"
          alertMessage="Thank you. Your request has been successfully sent."
        />
      )}
      {isSubmissionError && (
        <SnackbarFeedback
          alertType="error"
          alertMessage="We're sorry. There was a problem submitting your request, please try again."
        />
      )}
    </Fragment>
  );
};

export default SupportRequestForm;
