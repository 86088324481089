// External Imports
import React, { Fragment } from "react";
// Material UI
import { Grid, Stack, Typography } from "@mui/material";
import {
  CancelPresentation,
  Check,
  CreditCard,
  ElectricBolt,
  SupportAgent,
} from "@mui/icons-material";
// Config
import { colors, ArrowMaskBackround } from "../../../exports/assets.exports";
import {
  CallToActionBanner,
  BorderedTextParagraph,
} from "../../../exports/components.exports";
import { getMaterialButton } from "../../../exports/functions.exports";

/**
 * @function DynamicCallToActionBanner
 * @returns the call to action for the dynamic pages
 */
const DynamicCallToActionBanner = ({ blockData }: { blockData: any }) => {
  const { Title, Subtitle, Button, InfoIconText, SubtitleBorderedText } =
    blockData;

  const infoIconText = () => {
    return (
      <Grid
        container
        sx={{ maxWidth: { lg: "50%", md: "65%", sm: "80%", xs: "100%" } }}
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Check sx={{ marginRight: "0.7rem", fontSize: "1.75rem" }} /> 14 Day
            Free Trial UK-based team
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <CreditCard sx={{ marginX: "0.7rem", fontSize: "1.75rem" }} /> No
            credit card required
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <ElectricBolt sx={{ marginX: "0.7rem", fontSize: "1.75rem" }} />{" "}
            Quick and easy setup
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <CancelPresentation
              sx={{ marginX: "0.7rem", fontSize: "1.75rem" }}
            />{" "}
            No long contract, cancel any time
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={colors.swiftcaseWhite}
            sx={{
              paddingBottom: "0",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <SupportAgent sx={{ marginX: "0.7rem", fontSize: "1.75rem" }} />{" "}
            Help and advice from our UK-based support team
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getFooterContent = () => {
    if (Button && InfoIconText) {
      return (
        <Stack spacing={2} direction="column" alignItems="flex-start">
          {getMaterialButton(Button)}
          {infoIconText()}
        </Stack>
      );
    } else if (Button) {
      return getMaterialButton(Button);
    } else if (InfoIconText) {
      return infoIconText();
    } else return undefined;
  };

  return (
    <Fragment>
      <CallToActionBanner
        backgroundColor={colors.swiftcaseBackgroundBlue}
        backgroundImage={ArrowMaskBackround}
        backgroundPosition="top"
        backgroundSize="cover"
        headerContent={
          <Typography
            variant="h1"
            className="about-panel-one__header"
            color={colors.swiftcaseWhite}
          >
            {Title}
          </Typography>
        }
        bodyContent={
          SubtitleBorderedText ? (
            <BorderedTextParagraph
              borderColor={colors.swiftcaseWebsiteBlue}
              border="left"
              fontColor={colors.swiftcaseWhite}
              paragraphs={[Subtitle]}
            />
          ) : (
            <Typography
              variant="body1"
              className="about-panel-one__header"
              color={colors.swiftcaseWhite}
            >
              {Subtitle}
            </Typography>
          )
        }
        footerContent={getFooterContent()}
      />
    </Fragment>
  );
};

export default DynamicCallToActionBanner;
