// External Imports
import React from "react";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { getMarkdownText } from "../../../exports/functions.exports";
// Components
import { TipsAndUpdatesOutlined } from "@mui/icons-material";
import { Grid, Typography, CardHeader, Card, CardContent } from "@mui/material";

/**
 * @function TipElement component
 * @param tipText data from strapi for the relevant component
 * @returns the tip element, within a grid item
 */
const TipElement = ({ tipText }: { tipText: any }) => {
  const { MarkdownText, PlainText } = tipText;
  return (
    <Grid item xs={12} className="dynamic-guide__grid-item--tip-element">
      <Card
        className="dynamic-guide__tip-element"
        sx={{
          backgroundColor: colors.swiftcaseGoldOpaque,
          border: `0.125rem solid ${colors.swiftcaseGold}`,
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={
            <TipsAndUpdatesOutlined
              sx={{ fontSize: "3rem", color: colors.swiftcaseMediumGreen }}
            />
          }
          titleTypographyProps={{
            variant: "h4",
            fontWeight: theme.fontWeights.swiftcaseSemiBold,
          }}
          title="Tips:"
        />
        <CardContent>
          <Typography variant="body1" color={colors.swiftcaseDarkBlue}>
            {PlainText ? PlainText : getMarkdownText(MarkdownText)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TipElement;
