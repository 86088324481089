// Material UI
import { Box, Container } from "@mui/material";
// External Imports
import React, { Fragment } from "react";
// Config
import { IPanelWrapperProps } from "../../../exports/types.exports";

/**
 * @function PanelWrapper component
 * @returns the panel wrapper, where the content height, width, color are all set
 * along with background color or image & positioning, as well as children components
 */
const PanelWrapper = ({
  contentWidth,
  minHeight,
  medium,
  children,
  listOfChildren,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  color,
  overlayImage,
  alterZIndex,
  centered,
  justifyContent,
}: IPanelWrapperProps) => {
  const getChildren = () => {
    return children
      ? children
      : listOfChildren?.map((childElement, index) => {
          return childElement;
        });
  };

  return (
    <Fragment>
      <Box
        className="panel-wrapper"
        sx={{
          minHeight,
          backgroundColor,
          backgroundImage: `url(${backgroundImage})` || null,
          backgroundPosition,
          backgroundSize,
          color,
          height: { md: medium } || "100%",
          width: "100%",
          position: "relative",
          zIndex: alterZIndex ?? 20,
          display: centered ? "flex" : "",
          alignItems: centered ? "center" : "",
          paddingY: "10rem",
        }}
      >
        <Container
          className="panel-wrapper__container"
          sx={{
            marginY: centered ? "auto" : "",
            height: "100%",
            width: "100%",
            display: centered ? "flex" : "",
            alignItems: centered ? "center" : "",
            justifyContent: justifyContent ?? "",
            position: "relative",
          }}
          maxWidth={contentWidth}
        >
          {getChildren()}
        </Container>

        {overlayImage ?? null}
      </Box>
    </Fragment>
  );
};

export default PanelWrapper;
