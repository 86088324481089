// External Imports
import React from "react";
// Material UI
import { Typography } from "@mui/material";
// Config
import {
  SinglePanelLayout,
  PanelWrapper,
  BorderedTextParagraph,
} from "../../../exports/components.exports";
import {
  getColorScheme,
  getMaterialButton,
} from "../../../exports/functions.exports";

/**
 * @function SingleColumnLayout
 * @returns the partners panel four component
 */
const SingleColumnLayout = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    PanelHeadDescription,
    Subtitle,
    Title,
    BodyContent,
    BodyContentType,
    PanelHeadDescriptionPosition,
    BodyContentPosition,
    Button,
  } = blockData;

  /**
   * @function getBodyContent
   * @param BodyContentType type of body content
   * @param text the text of the body content
   * @param colorScheme the color to be applied
   * @returns the element desired
   */
  const getBodyContent = (
    BodyContentType: string,
    text: string,
    colorScheme: string
  ) => {
    const paragraphs = text
      .split("//")
      .map((paragraph: string) => paragraph.trim());

    switch (BodyContentType) {
      case "bodyText":
        return (
          <Typography variant="body1" sx={{ color: colorScheme }}>
            {text}
          </Typography>
        );
      case "headerText":
        return (
          <Typography variant="h2" sx={{ color: colorScheme }}>
            {text}
          </Typography>
        );
      case "extraLargeText":
        return (
          <Typography
            variant="h2"
            sx={{ fontSize: "12rem", fontWeight: 900, color: colorScheme }}
          >
            {text}
          </Typography>
        );
      case "borderedText":
        return (
          <BorderedTextParagraph
            borderColor={getColorScheme(ColorScheme)?.primary}
            border="left"
            paragraphs={paragraphs}
            fontColor={getColorScheme(ColorScheme)?.secondary}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <SinglePanelLayout
        panelDescription={PanelHeadDescription}
        centrePanelDescription={PanelHeadDescriptionPosition}
        title={Title}
        subTitle={Subtitle}
        primaryTextColor={getColorScheme(ColorScheme)?.primary}
        secondaryTextColor={getColorScheme(ColorScheme)?.secondary}
        singleBodyContent={getBodyContent(
          BodyContentType,
          BodyContent,
          getColorScheme(ColorScheme)?.secondary
        )}
        singleBodyContentPosition={BodyContentPosition}
        footer={getMaterialButton(Button)}
      />
    </PanelWrapper>
  );
};

export default SingleColumnLayout;
