// External Imports
import React from "react";
// Config
import {
  getColorScheme,
  getImageSize,
} from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";
// Components
import {
  PanelWrapper,
  PaddedImage,
  MediaCard,
  ThreeColumnFlex,
} from "../../../exports/components.exports";

/**
 * @function ThreeColumnTextImages
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const ThreeColumnTextImages = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    LeftImage,
    LeftPosition,
    RightImage,
    RightPosition,
    CenterTitle,
    CenterSubtitle,
    LeftImageSize,
    RightImageSize,
  } = blockData;

  const getImageContent = (image: any, imageSize: string) => {
    return <PaddedImage image={image.url} maxWidth={getImageSize(imageSize)} />;
  };

  return (
    <PanelWrapper
      minHeight="65vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <ThreeColumnFlex
        minHeight="65vh"
        placement="space-between"
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement={LeftPosition}
        leftPanelHeight="100%"
        centerPanelContentXPlacement="center"
        centerPanelContentYPlacement="center"
        centerPanelHeight="100%"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement={RightPosition}
        rightPanelHeight="100%"
        left={getImageContent(LeftImage, LeftImageSize)}
        center={
          <MediaCard
            data={{ title: CenterTitle, description: CenterSubtitle }}
            titleColor={colors.swiftcaseDarkBlue}
            descriptionColor={colors.swiftcaseDarkBlue}
            height="fit-content"
          />
        }
        right={getImageContent(RightImage, RightImageSize)}
      />
    </PanelWrapper>
  );
};

export default ThreeColumnTextImages;
