// External Imports
import React from "react";
// Config
import { colors } from "../../../exports/assets.exports";
import { getMarkdownText } from "../../../exports/functions.exports";
// Components
import { Grid, Typography } from "@mui/material";

/**
 * @function BodyText component
 * @param bodyText data for the strapi component of body text
 * @returns either the plain text in a typography element or the converted markdown, both within a grid item
 */
const BodyText = ({ bodyText }: { bodyText: any }) => {
  const { MarkdownText, PlainText } = bodyText;

  if (MarkdownText) {
    return (
      <Grid
        item
        xs={12}
        className="dynamic-guide__grid-item"
        id="markdown-content"
      >
        {getMarkdownText(MarkdownText)}
      </Grid>
    );
  }

  if (PlainText) {
    return (
      <Grid item xs={12} className="dynamic-guide__grid-item">
        <Typography
          variant="body1"
          className="dynamic-guide__body-text-plain"
          color={colors.swiftcaseDarkBlue}
        >
          {PlainText}
        </Typography>
      </Grid>
    );
  }

  return null;
};

export default BodyText;
