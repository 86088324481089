import React from "react";
import { Typography } from "@mui/material";

export const getHeader = (title: string, color: string) => {
  return (
    <Typography variant="h2" align="left" color={color} padding={0}>
      {title}
    </Typography>
  );
};

export const getSubheader = (subtitle: string, color: string) => {
  return (
    <Typography variant="h3" align="left" color={color} padding={0}>
      {subtitle}
    </Typography>
  );
};

export const getParagraphs = (paragraphs: Array<string>, color: string) => {
  return paragraphs.map((paragraph: string, index: number) => {
    return (
      <Typography key={index} variant="body1" color={color}>
        {paragraph}
      </Typography>
    );
  });
};
