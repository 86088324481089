// External Imports
import React, { useState } from "react";
// Material UI
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Star,
} from "@mui/icons-material";
import {
  Box,
  MobileStepper,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
// Config
import { colors } from "../../exports/assets.exports";
import { ICustomerReviews } from "exports/types.exports";

interface IStepperProps {
  data: Array<ICustomerReviews>;
  descriptionColor?: string;
  subtitleColor?: string;
  actionButtonColor?: string;
  stars?: boolean;
}

/**
 * @function TextMobileStepper component
 * @returns the stepped/carousel view with a description, sub title and image/logo
 */
const TextMobileStepper = ({
  data,
  descriptionColor,
  subtitleColor,
  actionButtonColor,
  stars,
}: IStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = data.length;

  /**
   * @function handleNext
   * @returns sets the next active step for the component
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * @function handleBack
   * @returns sets the previous active step for the component
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * used to handle inverting a black company logo
   */
  const invertImage = data[activeStep].subtitle.includes("Skinny");

  return (
    <Box
      sx={{
        maxWidth: { md: "75%", xs: "100%" },
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "3rem",
        width: "100%",
      }}
      className="stepped-carousel"
    >
      <Box
        sx={{
          minHeight: { lg: "20rem", md: "25rem", xs: "35rem" },
          height: "fit-content",
          overflowY: "auto",
          p: 2,
        }}
        className="stepped-carousel__header"
      >
        <Typography
          variant="h3"
          color={descriptionColor ?? colors.swiftcaseWhite}
          className="stepped-carousel__description"
          sx={{
            textAlign: { md: "left", xs: "center" },
          }}
        >
          {data[activeStep].description}
        </Typography>
      </Box>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { md: "row", xs: "column" },
          gap: { md: 0, xs: 2 },
          height: "5rem",
          width: "100%",
          paddingLeft: { md: 2, xs: 0 },
          backgroundColor: "transparent",
          textAlign: { md: "left", xs: "center" },
          marginX: "auto",
        }}
        className="stepped-carousel__paper"
      >
        <Typography
          variant="h3"
          color={subtitleColor ?? colors.swiftcaseWhite}
          sx={{
            opacity: 0.5,
          }}
          className="stepped-carousel__subtitle"
        >
          {data[activeStep].subtitle}
        </Typography>
        {data[activeStep].image ? (
          <Box sx={{ marginTop: { md: "0", xs: "1.5rem" } }}>
            <img
              className="stepped-carousel__image"
              src={data[activeStep].image}
              alt={`${data[activeStep].subtitle} - Company Logo`}
              loading="lazy"
              style={{
                width: "6rem",
                height: "100%",
                filter: invertImage ? "invert(1)" : "none",
              }}
            />
          </Box>
        ) : null}
      </Paper>

      {(stars || data[activeStep].fiveStars) && (
        <Box
          className="stepper__stars-container"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: { md: "0", xs: "15rem" },
          }}
        >
          <Box
            className="company-review__ratings"
            sx={{
              "& > :not(style)": {
                marginX: "1rem",
              },
              color:
                stars && data[activeStep].fiveStars === false
                  ? "transparent"
                  : "#f4a34e",
              //   This is to handle the cases on the dynamic carousel where one item has a rating but the next doesn't
            }}
          >
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </Box>
        </Box>
      )}

      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        className="stepped-carousel__stepper-menu"
        activeStep={activeStep}
        sx={{
          backgroundColor: "transparent",
          marginX: "auto",
          width: "100%",
          height: "5rem",
          marginTop: stars ? "0" : { md: "0", xs: "12rem" },
          color: actionButtonColor ?? colors.swiftcaseWhite,
          paddingX: 0,
          paddingY: "1rem",
          ".MuiMobileStepper-dot": {
            backgroundColor: actionButtonColor ?? colors.swiftcaseWhite,
            width: "1.4rem",
            height: "1.4rem",
            marginX: "0.75rem",
          },
          ".MuiMobileStepper-dotActive": {
            backgroundColor: colors.swiftcaseBlue,
          },
        }}
        nextButton={
          <IconButton
            size="large"
            onClick={handleNext}
            className="stepped-carousel__next-button"
            disabled={activeStep === maxSteps - 1}
            sx={{
              color: actionButtonColor ?? colors.swiftcaseWhite,
              ".MuiSvgIcon-root": {
                fontSize: "3.5rem",
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton
            size="large"
            className="stepped-carousel__back-button"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              color: actionButtonColor ?? colors.swiftcaseWhite,
              ".MuiSvgIcon-root": {
                fontSize: "3.5rem",
              },
            }}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default TextMobileStepper;
