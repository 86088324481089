// External Imports
import React from "react";
// Config
import { colors } from "../../../exports/assets.exports";
import {
  getColorScheme,
  getMaterialButton,
} from "../../../exports/functions.exports";
// Components
import { PanelWrapper } from "../../../exports/components.exports";
import { Typography, Grid } from "@mui/material";

/**
 * @function SimpleOneColumn
 * @returns the simple one column layout
 */
const SimpleOneColumn = ({ blockData }: { blockData: any }) => {
  const { ColorScheme, Title, Button } = blockData;

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <Grid
        container
        sx={{ alignItems: "center", justifyContent: "center" }}
        className="simple-one-column"
        spacing={4}
      >
        <Grid item xs={12} className="simple-one-column__grid-item">
          <Typography
            color={colors.swiftcaseWhite}
            className="simple-one-column__header"
            variant="h2"
            textAlign="center"
            sx={{ color: getColorScheme(ColorScheme)?.primary }}
          >
            {Title}
          </Typography>
        </Grid>
        <Grid
          className="simple-one-column__grid-item"
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {getMaterialButton(Button)}
        </Grid>
      </Grid>
    </PanelWrapper>
  );
};

export default SimpleOneColumn;
