/**
 * @constant urlRoutes
 * All routes used within the project
 */
export const urlRoutes = {
  HOMEPAGE: "/",
  GUIDES: "/guides",
  HELP_TO_GROW_DIGITAL: "/help-to-grow-digital",
  ABOUT: "/about",
  HOW_IT_WORKS: "/how-it-works",
  BLOG: "/blogs",
  PRICING: "/pricing",
  CONTACT: "/contact-us",
  CAREERS: "/careers",
  PRIVACY_POLICY: "/privacy-policy",
  PARTNER: "/partner",
  MORTGAGE_BROKERS: "/mortgage-brokers",
  SURVEYORS: "/surveyors",
  EMAIL_MANAGEMENT: "/email-management",
  CONNECT_APPS: "/connect-your-apps",
  CRM_SIGNUP: "https://crm.swiftcase.co.uk/signup",
  SUPPORT: "/support",
};
