// External
import React, { Fragment } from "react";
import { Grid, Link, Typography } from "@mui/material";
// Assets
import { colors, theme } from "../../../exports/assets.exports";
import { EmailSignUp } from "../../../exports/components.exports";
// Config
import { IWebsiteEmailPanelProps } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";

/**
 * @function SwiftcaseWebsiteEmailPanel
 * @returns the email sign-up panel, reusable across the site
 *
 * @todo hook up the message or chat link inside the text to include a link
 */
const SwiftcaseWebsiteEmailPanel = ({
  headerText,
  buttonText,
  fullDynamicHeader = false,
}: IWebsiteEmailPanelProps) => {
  return (
    <Fragment>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        spacing={2}
        maxWidth={{ md: "90%", xs: "100%" }}
        className="swiftcase-email-panel"
      >
        <Grid
          item
          xs={12}
          className="swiftcase-email-panel__grid-item"
          sx={{ marginBottom: "2rem" }}
        >
          {!fullDynamicHeader && (
            <Typography
              variant="h2"
              align="left"
              color={colors.swiftcaseDarkBlue}
            >
              {headerText ? `${headerText}:` : null} Get up and running
              <Typography
                variant="h2"
                component="span"
                color={colors.swiftcaseWebsiteBlue}
                sx={{ lineHeight: "inherit" }}
              >
                {" "}
                quickly and easily.{" "}
              </Typography>
            </Typography>
          )}
          {fullDynamicHeader && (
            <Typography
              variant="h2"
              align="left"
              color={colors.swiftcaseDarkBlue}
            >
              {headerText}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className="swiftcase-email-panel__grid-item">
          <Typography variant="body1" color={colors.swiftcaseDarkBlue}>
            We’d love to show you SwiftCase, so, enter your work email address
            below to start your free, no-obligation, trial (no credit card
            required).
          </Typography>
        </Grid>
        <Grid item xs={12} className="swiftcase-email-panel__grid-item">
          <EmailSignUp
            buttonText={buttonText}
            infoTextColor={colors.swiftcaseMidGrey}
          />
        </Grid>
        <Grid item xs={12} className="swiftcase-email-panel__grid-item">
          <Typography
            variant="body1"
            align="left"
            color={colors.swiftcaseDarkBlue}
          >
            <Typography
              fontSize="inherit"
              component="span"
              fontWeight={theme.fontWeights.swiftcaseSemiBold}
              color="inherit"
            >
              {" "}
              PS Like To Learn A Bit More First?{" "}
            </Typography>
            We’re happy to​
            <Link
              fontSize="inherit"
              component="a"
              color={colors.swiftcaseWebsiteBlue}
              href={urlRoutes.CONTACT}
              underline="hover"
            >
              {" "}
              message or chat{" "}
            </Link>
            – our SwiftCase experts will walk you through how we can help – no
            pushy salespeople guaranteed.
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SwiftcaseWebsiteEmailPanel;
