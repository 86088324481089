// External Imports
import React from "react";
// Config
import { getColorScheme } from "../../../exports/functions.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  TextPanelTitleSubtitle,
} from "../../../exports/components.exports";

/**
 * @function TwoColumnTitleAndSubtitle
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const TwoColumnTitleAndSubtitle = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    ReverseColumnLayout,
    LeftSubtitle,
    LeftTitle,
    RightSubtitle,
    RightTitle,
  } = blockData;

  const rightContent = () => {
    return (
      <TextPanelTitleSubtitle
        title={RightTitle}
        subTitle={RightSubtitle}
        titleColor={getColorScheme(ColorScheme)?.primary}
        subTitleColor={getColorScheme(ColorScheme)?.secondary}
      />
    );
  };

  const leftContent = () => {
    return (
      <TextPanelTitleSubtitle
        title={LeftTitle}
        subTitle={LeftSubtitle}
        titleColor={getColorScheme(ColorScheme)?.primary}
        subTitleColor={getColorScheme(ColorScheme)?.secondary}
      />
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default TwoColumnTitleAndSubtitle;
