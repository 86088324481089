// External Imports
import React from "react";
// Material UI
import { Box, Grid, Typography } from "@mui/material";
import { BlueDots, colors } from "../../../exports/assets.exports";
// Components
import {
  FreeTrialSignUp,
  TwoColumnFlex,
  PaddedImage,
  BorderedTextParagraph,
  PanelWrapper,
} from "../../../exports/components.exports";
import {
  getMaterialButton,
  getMarkdownText,
} from "../../../exports/functions.exports";

/**
 * @function HomepageHero component
 * @returns the first panel for the website homepage
 */
const HomepageHero = ({ blockData }: { blockData: any }) => {
  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h1" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getBorderedText function to return the bordered text component
   */
  const getBorderedText = (paragraphs: Array<string>) => {
    return (
      <Grid item xs={12}>
        <BorderedTextParagraph
          borderColor={colors.swiftcaseMediumBlue}
          border="left"
          paragraphs={paragraphs}
          fontColor={colors.swiftcaseOffwhite}
        />
      </Grid>
    );
  };

  const RightContent = () => {
    return (
      <Box
        sx={{
          minHeight: "25rem",
          minWidth: "25rem",
          display: { md: "block", xs: "none" },
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <PaddedImage
          image="https://livepoint-swiftcase-cms.s3.eu-west-2.amazonaws.com/no_code_build_9ed0b2b09c.webp"
          maxWidth="100rem"
          noBorder={0}
          noShadow="none"
          backgroundColor="transparent"
          eagerLoading
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: "-1",
            width: "100%",
            height: "100%",
            bottom: { lg: "-10rem", md: "0rem" },
            right: { lg: "-10rem", md: "0rem" },
            opacity: 0.4,
            overflow: "visible",
            ".padded-image__wrapper": {
              width: "100rem",
            },
          }}
        >
          <PaddedImage
            image={BlueDots}
            maxWidth="100rem"
            noBorder={0}
            noShadow="none"
            backgroundColor="transparent"
            eagerLoading
          />
        </Box>
      </Box>
    );
  };

  const LeftContent = () => {
    let paragraphs: Array<string | any> = [];
    blockData?.Paragraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    return (
      <Grid container className="homepage-hero" spacing={2}>
        {blockData?.Title && (
          <Grid item className="homepage-hero__grid-item" xs={12}>
            {getTypography("h1", blockData?.Title, colors.swiftcaseWhite)}
          </Grid>
        )}
        {blockData?.ComplexColouredTitle && (
          <Grid item xs={12} className="homepage-hero__grid-item">
            <Typography
              variant="h1"
              align="left"
              color={colors.swiftcaseWhite}
              sx={{ fontSize: { md: "5rem", xs: "4.5rem" } }}
            >
              The Software Solution to
              <Typography
                className="homepage-panel-one__nested-text"
                component="span"
                sx={{
                  color: colors.swiftcasePink,
                  lineHeight: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                }}
              >
                {" "}
                Automate{" "}
              </Typography>
              your{" "}
              <Typography
                className="homepage-panel-one__nested-text"
                component="span"
                sx={{
                  color: colors.swiftcaseBlue,
                  lineHeight: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                }}
              >
                {" "}
                Business{" "}
              </Typography>
              and
              <Typography
                className="homepage-panel-one__nested-text"
                component="span"
                sx={{
                  color: colors.swiftcaseGreen,
                  lineHeight: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                }}
              >
                {" "}
                Manage{" "}
              </Typography>
              Tasks.
            </Typography>
          </Grid>
        )}
        {blockData?.Paragraphs && blockData?.ParagraphBorderedText
          ? getBorderedText(paragraphs)
          : paragraphs.map((text: string, index: number) => {
              return (
                <Grid item xs={12} key={index}>
                  {getTypography("body1", text, colors.swiftcaseWhite)}
                </Grid>
              );
            })}

        {blockData?.UseEmailSignUp && (
          <Grid item xs={12} className="homepage-hero__grid-item">
            <FreeTrialSignUp alignment="start" fullWidth />
          </Grid>
        )}
        {blockData?.LeftButton && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            {getMaterialButton(blockData?.Button)}
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={colors.swiftcaseBackgroundBlue}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={<RightContent />}
        left={<LeftContent />}
        leftPanelContentXPlacement="left"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="right"
        rightPanelContentYPlacement="center"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default HomepageHero;
