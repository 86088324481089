// External Imports
import React from "react";
import { getMaterialButton } from "../../../exports/functions.exports";
// Components
import { Grid } from "@mui/material";

/**
 * @function ButtonRow component
 * @param buttonData the data strapi for this component
 * @returns the buttons, centered and spaced out between a grid item, uses the material ui button
 */
const ButtonRow = ({ buttonData }: { buttonData: Array<any> }) => {
  const getButtons = () => {
    return buttonData.map((button: any) => {
      return getMaterialButton(button);
    });
  };

  return (
    <Grid
      item
      xs={12}
      className="dynamic-guide__grid-item-button-row"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5rem",
      }}
    >
      {getButtons()}
    </Grid>
  );
};

export default ButtonRow;
