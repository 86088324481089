import { Box, Grid } from "@mui/material";
import React, { forwardRef } from "react";
import { ITwoColumnFlexProps } from "../../../exports/types.exports";
import { getPanelConentPlacement } from "../../../exports/functions.exports";

const TwoColumnFlex = forwardRef(
  (
    {
      contentWidth,
      minHeight,
      placement,
      alignment,
      left,
      right,
      leftPanelContentXPlacement,
      leftPanelContentYPlacement,
      leftPanelHeight,
      rightPanelContentXPlacement,
      rightPanelContentYPlacement,
      rightPanelHeight,
      backgroundColor,
      backgroundImage,
      backgroundPosition,
      backgroundSize,
      color,
      alterZIndex,
      padding,
      noFixedHeight = false,
      wrapReverse,
    }: ITwoColumnFlexProps,
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className="two-column-flex"
        display="flex"
        justifyContent="center"
        sx={{
          minHeight,
          backgroundColor,
          backgroundImage: `url(${backgroundImage})` || null,
          backgroundPosition,
          backgroundSize,
          color,
          height: "100%",
          width: "100%",
          position: "relative",
          zIndex: alterZIndex ?? 20,
          padding: padding ?? 0,
        }}
      >
        <Grid
          container
          maxWidth={contentWidth}
          display="flex"
          justifyContent={placement}
          sx={{
            flexWrap: { md: "initial", xs: wrapReverse ? "wrap-reverse" : "" },
          }}
          alignItems={alignment}
          className="two-column-flex__grid-container"
        >
          <Grid
            className="two-column-flex__grid-item-left"
            item
            display="flex"
            justifyContent={getPanelConentPlacement(
              leftPanelContentXPlacement!
            )}
            alignItems={getPanelConentPlacement(leftPanelContentYPlacement!)}
            sx={{
              marginTop: wrapReverse ? { md: "0", xs: "3rem" } : "0",
              height: noFixedHeight
                ? undefined
                : {
                    md: leftPanelHeight ?? "100%",
                    xs: "50%",
                  },
            }}
            md={5.5}
            xs={12}
          >
            {left}
          </Grid>
          <Grid
            className="two-column-flex__grid-item-right"
            item
            display="flex"
            justifyContent={getPanelConentPlacement(
              rightPanelContentXPlacement!
            )}
            alignItems={getPanelConentPlacement(rightPanelContentYPlacement!)}
            md={5.5}
            xs={12}
            sx={{
              marginTop: wrapReverse ? "0" : { md: "0", xs: "3rem" },
              height: noFixedHeight
                ? undefined
                : {
                    md: rightPanelHeight ?? "100%",
                    xs: "50%",
                  },
            }}
          >
            {right}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default TwoColumnFlex;
