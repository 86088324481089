// External Imports
import React from "react";
import { Grid, Typography } from "@mui/material";
// Config
import {
  getImageSize,
  getMarkdownText,
} from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  PaddedImage,
  BorderedTextParagraph,
  SwiftcaseWebsiteEmailPanel,
} from "../../../exports/components.exports";

/**
 * @function TwoColEmailCapture
 * @param blockData from strapi
 * @returns the two column email capture
 */
const TwoColEmailCapture = ({ blockData }: { blockData: any }) => {
  const { ReverseColumnLayout, LeftImageSize } = blockData;

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getImageContent
   * @param image to be rendered
   * @returns the image inside a padded image component
   */
  const getImageContent = (image: any, imageSize: string) => {
    return (
      <PaddedImage
        noBorder={0}
        noShadow="none"
        backgroundColor="transparent"
        image={image.url}
        maxWidth={getImageSize(imageSize)}
      />
    );
  };

  /**
   * @function getBorderedText function to return the bordered text component
   */
  const getBorderedText = (paragraphs: Array<string>) => {
    return (
      <Grid item xs={12}>
        <BorderedTextParagraph
          borderColor={colors.swiftcaseDarkBlue}
          border="left"
          paragraphs={paragraphs}
          fontColor={colors.swiftcaseMidGrey}
        />
      </Grid>
    );
  };

  const rightContent = () => {
    return (
      <SwiftcaseWebsiteEmailPanel
        headerText={blockData.EmailPanelTitle}
        buttonText={blockData.EmailPanelButtonText}
        fullDynamicHeader
      />
    );
  };

  const leftContent = () => {
    let paragraphs: Array<string | any> = [];
    blockData?.LeftParagraphs.map((text: any) => {
      if (text.Paragraph === null || text.Paragraph === "") {
        paragraphs.push(getMarkdownText(text.MarkdownParagraph));
      } else {
        paragraphs.push(text.Paragraph);
      }
    });

    return (
      <Grid
        container
        spacing={4}
        className="complex-two-column__left"
        sx={{ alignSelf: blockData?.LeftTitle === null ? "center" : "stretch" }}
      >
        {blockData?.LeftTitle && (
          <Grid item xs={12}>
            {getTypography(
              "h2",
              blockData?.LeftTitle,
              colors.swiftcaseDarkBlue
            )}
          </Grid>
        )}
        {blockData?.LeftParagraphs && blockData?.LeftBorderedText
          ? getBorderedText(paragraphs)
          : paragraphs.map((text: string, index: number) => {
              return (
                <Grid item xs={12} key={index}>
                  {getTypography("body1", text, colors.swiftcaseMidGrey)}
                </Grid>
              );
            })}
        {blockData?.LeftImage && (
          <Grid
            item
            xs={12}
            sx={{
              ".padded-image__wrapper": {
                marginX: "auto",
              },
            }}
          >
            {getImageContent(blockData?.LeftImage, LeftImageSize)}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={colors.swiftcaseOffwhite}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default TwoColEmailCapture;
