// External
import React, { Fragment, useState } from "react";
// Material UI
import { TextField, Button, CircularProgress, Grid } from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import { IResponse, ITaskKeyValuePair } from "../../../exports/types.exports";
import { createTask } from "../../../exports/functions.exports";
import { SnackbarFeedback } from "../../../exports/components.exports";

/**
 * @function ApplyNowForm
 * @returns the apply now form
 */
const ApplyNowForm = () => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState("");
  const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [isSubmissionError, setIsSubmissionError] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  /**
   * resets state of form fields
   */
  const resetForm = () => {
    setFirstName("");
    setSurname("");
    setEmail("");
    setCompany("");
    setJobTitle("");
    setCompanyWebsiteUrl("");
  };

  /**
   *
   * @returns List of fields tied to controls to manage the form conents
   */
  const getFormFields = () => {
    return (
      <Fragment>
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          label="First Name"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          placeholder="Surname"
          label="Surname"
          required
          sx={{
            margin: "1rem 0",
            borderColor: colors.swiftcaseBlue,
          }}
        />
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          label="Email"
          required
          sx={{ margin: "1rem 0" }}
        />
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          placeholder="Job Title"
          label="Job Title"
          required
          sx={{ margin: "1rem 0" }}
        />
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          placeholder="Company Name"
          label="Company Name"
          required
          sx={{ margin: "1rem 0" }}
        />
        <TextField
          onKeyUp={validateForm}
          fullWidth
          className="partners-apply-now-form__text-field"
          value={companyWebsiteUrl}
          onChange={(e) => setCompanyWebsiteUrl(e.target.value)}
          placeholder="Company website URL"
          label="Company website URL"
          required
          sx={{ margin: "1rem 0" }}
        />
      </Fragment>
    );
  };

  /**
   *
   * @returns Relevant button based on form submission state
   */
  const getButton = () => {
    return isSubmissionSuccess ? (
      <Button
        disabled
        className="partners-apply-now-form__button"
        size="medium"
        variant="contained"
        sx={{
          height: "100%",
          backgroundColor: colors.swiftcaseWebsitePink,
          margin: "2rem 0",
          ":hover": {
            backgroundColor: colors.swiftcaseWebsitePink,
            color: colors.swiftcaseWhite,
            textDecoration: "underline",
          },
        }}
      >
        Success
      </Button>
    ) : isProcessingSubmission ? (
      <Button
        disabled
        className="partners-apply-now-form__button"
        size="medium"
        sx={{
          height: "100%",
          backgroundColor: colors.swiftcaseWebsitePink,
          margin: "2rem 0",
          ":hover": {
            backgroundColor: colors.swiftcaseWebsitePink,
            color: colors.swiftcaseWhite,
            textDecoration: "underline",
          },
        }}
      >
        <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
      </Button>
    ) : (
      <Button
        onClick={performContactRequest}
        variant="contained"
        color={!formHasError ? "primary" : "error"}
        sx={{
          width: { md: "50%", xs: "100%" },
          margin: "1rem 0",
        }}
      >
        {!formHasError ? "Submit" : "Please fill all fields"}
      </Button>
    );
  };

  const validateForm = () => {
    let hasError = false;

    if (
      firstName !== "" &&
      surname !== "" &&
      email !== "" &&
      company !== "" &&
      jobTitle !== "" &&
      companyWebsiteUrl !== ""
    ) {
      if (formHasError) {
        setFormHasError(hasError);
      }
    } else {
      hasError = true;
      setFormHasError(hasError);
    }

    return hasError;
  };

  /**
   * Builds request body and calls service method "Create Task" to create Partner Application task, handles redirect
   */
  const performContactRequest = async () => {
    if (!validateForm()) {
      let response: IResponse = { isAccepted: false, message: "" };
      setIsProcessingSubmission(true);

      const taskData: Array<ITaskKeyValuePair> = [
        {
          name: "name_1",
          value: `${firstName} ${surname}`,
        },
        {
          name: "client_email_address",
          value: `${email}`,
        },
        {
          name: "company",
          value: `${company}`,
        },
        {
          name: "contact_job_title",
          value: `${jobTitle}`,
        },
        {
          name: "website_url",
          value: `${companyWebsiteUrl}`,
        },
      ];

      response = await createTask(
        process.env.REACT_APP_PARTNER_INQUIRY_WORKFLOW_ID!,
        taskData,
        response
      );

      setIsProcessingSubmission(false);
      resetForm();

      if (response.isAccepted) {
        setIsSubmissionSuccess(true);
      } else {
        setIsSubmissionError(true);
      }
    }
  };

  return (
    <Fragment>
      <Grid container height="100%" className="partners-apply-now-form__grid">
        <Grid
          item
          xs={12}
          className="partners-apply-now-form__grid-item"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <form
            className="partners-apply-now-form"
            style={{ display: "flex", flexDirection: "column", width: "85%" }}
          >
            {getFormFields()}
            {getButton()}
          </form>
        </Grid>
      </Grid>
      {isSubmissionSuccess && (
        <SnackbarFeedback
          alertType="success"
          alertMessage="Thank you. Your request has been successfully sent."
        />
      )}
      {isSubmissionError && (
        <SnackbarFeedback
          alertType="error"
          alertMessage="We're sorry. There was a problem submitting your request, please try again."
        />
      )}
    </Fragment>
  );
};

export default ApplyNowForm;
