// External Imports
import React, { useState, Fragment } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
  Stack,
  Paper,
} from "@mui/material";
// Config
import {
  getColorScheme,
  getImageSize,
  createTask,
  getMarkdownText,
} from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";
import { IResponse, ITaskKeyValuePair } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
// Components
import {
  PanelWrapper,
  TwoColumnFlex,
  PaddedImage,
  SnackbarFeedback,
} from "../../../exports/components.exports";

/**
 * @function EmailNameCaptureForm
 * @param blockData from strapi
 * @returns the two column title text layout
 */
const EmailNameCaptureForm = ({ blockData }: { blockData: any }) => {
  const {
    ColorScheme,
    ReverseColumnLayout,
    RightImageSize,
    LeftSubtitle,
    LeftTitle,
    RightTitle,
    RightSubtitle,
    RightImage,
    IncludeNameFields,
    SwiftcaseFileTag,
  } = blockData;

  let formattedLeftSubtitle = getMarkdownText(LeftSubtitle);
  let formattedRightSubtitle = getMarkdownText(RightSubtitle);

  const [isSubmitEmailSuccess, setIsSubmitEmailSuccess] = useState<
    true | false | "error"
  >(false);

  /**
   * @function getTypography
   * @param variant the type of text variant for material
   * @param text the content of the text
   * @param colorScheme the color to be applied
   * @returns the typography from material
   */
  const getTypography = (
    variant: "h2" | "h4" | "body1",
    text: string,
    colorScheme: string
  ) => {
    return (
      <Typography
        className="dynamic-typography"
        variant={variant}
        sx={{ color: colorScheme }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * @function getImageContent
   * @param image to be rendered
   * @returns the image inside a padded image component
   */
  const getImageContent = (image: any, imageSize: string) => {
    return (
      <PaddedImage
        noBorder={0}
        noShadow="none"
        image={image.url}
        backgroundColor="transparent"
        maxWidth={getImageSize(imageSize)}
      />
    );
  };

  const EmailCaptureForm = ({
    fileTagName,
    includeNames,
    callBack,
  }: {
    fileTagName: string;
    includeNames: boolean;
    callBack: (result: true | false | "error") => void;
  }) => {
    const [firstName, setFirstName] = useState("");
    const [surName, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
    const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
    const [isSubmissionError, setIsSubmissionError] = useState(false);
    const [formHasError, setFormHasError] = useState(false);

    /**
     * resets state of form fields
     */
    const resetForm = () => {
      setFirstName("");
      setSurname("");
      setEmail("");
    };

    /**
     * @function getFormFields
     * @returns List of fields tied to controls to manage the form conents
     */
    const getFormFields = () => {
      return (
        <Fragment>
          {includeNames && (
            <Fragment>
              <TextField
                onKeyUp={validateForm}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                label="First Name"
                required
                sx={{
                  margin: "1rem 0",
                  borderColor: colors.swiftcaseBlue,
                }}
              />
              <TextField
                onKeyUp={validateForm}
                value={surName}
                onChange={(e) => setSurname(e.target.value)}
                placeholder="Last Name"
                label="Last Name"
                required
                sx={{
                  margin: "1rem 0",
                  borderColor: colors.swiftcaseBlue,
                }}
              />
            </Fragment>
          )}
          <TextField
            onKeyUp={validateForm}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            label="Email"
            required
            sx={{ margin: "1rem 0" }}
          />
        </Fragment>
      );
    };

    /**
     * @function getButton
     * @returns Relevant button based on form submission state
     */
    const getButton = () => {
      return isSubmissionSuccess ? (
        <Button
          disabled
          variant="contained"
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
            backgroundColor: colors.swiftcaseGreen,
            ":hover": {
              backgroundColor: colors.swiftcaseGreen,
              textDecoration: "underline",
            },
          }}
        >
          Success
        </Button>
      ) : isProcessingSubmission ? (
        <Button
          disabled
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
          }}
        >
          <CircularProgress sx={{ color: colors.swiftcaseWhite }} />
        </Button>
      ) : (
        <Button
          onClick={performContactRequest}
          variant="contained"
          sx={{
            width: { md: "50%", xs: "100%" },
            margin: "1rem 0",
            backgroundColor: !formHasError
              ? colors.swiftcaseMediumBlue
              : colors.swiftcaseLightPink,
            ":hover": {
              textDecoration: "underline",
              backgroundColor: !formHasError
                ? colors.swiftcaseMediumBlue
                : colors.swiftcaseLightPink,
            },
          }}
        >
          {!formHasError ? "Submit" : "Please fill all fields"}
        </Button>
      );
    };

    const validateForm = () => {
      let hasError = false;

      if (email !== "") {
        if (formHasError) {
          setFormHasError(hasError);
        }
      } else if (
        (includeNames && firstName !== "") ||
        (includeNames && surName !== "")
      ) {
        if (formHasError) {
          setFormHasError(hasError);
        }
      } else {
        hasError = true;
        setFormHasError(hasError);
      }

      return hasError;
    };

    /**
     * Builds request body and calls service method "Create Task" to create Contact Us task, handles redirect
     */
    const performContactRequest = async () => {
      if (!validateForm()) {
        let response: IResponse = { isAccepted: false, message: "" };
        setIsProcessingSubmission(true);

        let taskData: Array<ITaskKeyValuePair> = [
          {
            name: "client_email_address",
            value: `${email}`,
          },
          {
            name: "file_tag_name",
            value: `${fileTagName}`,
          },
        ];

        if (IncludeNameFields) {
          taskData.push(
            {
              name: "first_name",
              value: `${firstName}`,
            },
            {
              name: "surname",
              value: `${surName}`,
            }
          );
        }

        response = await createTask(
          process.env.REACT_APP_WEBSITE_LEAD_MAGNET_WORKFLOW_ID!,
          taskData,
          response
        );

        setIsProcessingSubmission(false);
        resetForm();

        if (response.isAccepted) {
          setIsSubmissionSuccess(true);
          callBack(true);
        } else {
          setIsSubmissionError(true);
          callBack("error");
        }
      }
    };

    return (
      <Grid item xs={12}>
        <form className="swiftcase-contact-form">
          {getFormFields()}
          {getButton()}
        </form>
        {isSubmissionSuccess && (
          <SnackbarFeedback
            alertType="success"
            alertMessage="Thank you. Your details have been successfully sent."
          />
        )}
        {isSubmissionError && (
          <SnackbarFeedback
            alertType="error"
            alertMessage="We're sorry. There was a problem submitting your request, please try again."
          />
        )}
      </Grid>
    );
  };

  const handleCallback = (result: true | false | "error") => {
    setIsSubmitEmailSuccess(result);
  };

  const rightContent = () => {
    return (
      <Grid container spacing={4} className="complex-two-column__right">
        {RightTitle && (
          <Grid item xs={12}>
            {getTypography(
              "h2",
              RightTitle,
              getColorScheme(ColorScheme)?.primary
            )}
          </Grid>
        )}
        {formattedRightSubtitle && (
          <Grid item xs={12}>
            <Typography
              className="emailnamecaptureform__rightsubtitle"
              variant="body1"
              sx={{ color: getColorScheme(ColorScheme)?.secondary }}
            >
              {formattedRightSubtitle}
            </Typography>
          </Grid>
        )}
        {RightImage && (
          <Grid
            item
            xs={12}
            sx={{
              ".padded-image__wrapper": {
                marginX: "auto",
              },
            }}
          >
            {getImageContent(RightImage, RightImageSize)}
          </Grid>
        )}
      </Grid>
    );
  };

  const leftContent = () => {
    return (
      <Grid container spacing={4} className="complex-two-column__left">
        {LeftTitle && (
          <Grid item xs={12}>
            {getTypography(
              "h2",
              LeftTitle,
              getColorScheme(ColorScheme)?.primary
            )}
          </Grid>
        )}
        {formattedLeftSubtitle && (
          <Grid item xs={12}>
            <Typography
              className="emailnamecaptureform__leftsubtitle"
              variant="body1"
              sx={{ color: getColorScheme(ColorScheme)?.secondary }}
            >
              {formattedLeftSubtitle}
            </Typography>
          </Grid>
        )}
        {isSubmitEmailSuccess === false && (
          <EmailCaptureForm
            fileTagName={SwiftcaseFileTag}
            includeNames={IncludeNameFields}
            callBack={handleCallback}
          />
        )}
        {isSubmitEmailSuccess === true && (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                padding: "2rem",
                backgroundColor: getColorScheme(ColorScheme)?.background,
              }}
            >
              <Stack spacing={4} direction="column" alignItems="flex-start">
                {getTypography(
                  "h4",
                  `Thank you for submitting your email address${
                    IncludeNameFields ? " and name" : ""
                  }.`,
                  getColorScheme(ColorScheme)?.primary
                )}
              </Stack>
            </Paper>
          </Grid>
        )}
        {isSubmitEmailSuccess === "error" && (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                padding: "2rem",
                backgroundColor: getColorScheme(ColorScheme)?.background,
              }}
            >
              <Stack spacing={4} direction="column" alignItems="flex-start">
                {getTypography(
                  "h4",
                  "Something went wrong with submitting you're email address, please try again, or contact us here.",
                  getColorScheme(ColorScheme)?.primary
                )}
                <Button
                  href={urlRoutes.CONTACT}
                  variant="contained"
                  sx={{
                    backgroundColor: colors.swiftcaseWebsitePink,
                    ":hover": {
                      textDecoration: "underline",
                      backgroundColor: colors.swiftcaseWebsitePink,
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Stack>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <PanelWrapper
      minHeight="10vh"
      backgroundColor={getColorScheme(ColorScheme)?.background}
      contentWidth="lg"
      centered
    >
      <TwoColumnFlex
        placement="space-between"
        right={ReverseColumnLayout ? leftContent() : rightContent()}
        left={ReverseColumnLayout ? rightContent() : leftContent()}
        leftPanelContentXPlacement="center"
        leftPanelContentYPlacement="top"
        rightPanelContentXPlacement="center"
        rightPanelContentYPlacement="top"
        noFixedHeight
      />
    </PanelWrapper>
  );
};

export default EmailNameCaptureForm;
