// External Imports
import React from "react";
import {
  ConnectWithoutContact,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@mui/icons-material";
// Config
import { colors } from "../../../exports/assets.exports";

const getSocialMediaIcon = (icon?: string) => {
  switch (icon) {
    case "facebook":
      return (
        <Facebook
          sx={{
            fontSize: "3rem",
            color: colors.swiftcaseWebsiteBlue,
          }}
        />
      );
    case "instagram":
      return (
        <Instagram
          sx={{ fontSize: "3rem", color: colors.swiftcaseWebsiteBlue }}
        />
      );
    case "twitter":
      return (
        <Twitter
          sx={{ fontSize: "3rem", color: colors.swiftcaseWebsiteBlue }}
        />
      );
    case "linkedin":
      return (
        <LinkedIn
          sx={{
            fontSize: "3rem",
            color: colors.swiftcaseWebsiteBlue,
          }}
        />
      );
    case "youtube":
      return (
        <YouTube
          sx={{ fontSize: "3rem", color: colors.swiftcaseWebsiteBlue }}
        />
      );
    default:
      return (
        <ConnectWithoutContact
          sx={{ fontSize: "3rem", color: colors.swiftcaseWebsiteBlue }}
        />
      );
  }
};

export default getSocialMediaIcon;
