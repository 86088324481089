// External Imports
import React from "react";
// Material UI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  List,
} from "@mui/material";
import { CheckCircleOutlined, Star } from "@mui/icons-material";
// Config
import { colors, theme } from "../../../exports/assets.exports";
import { IPricingCardGridItemProps } from "../../../exports/types.exports";
import { GenerateIconListItems } from "../../../exports/functions.exports";

/**
 * @function PricingCardGridItem
 * @returns the price card grid item
 */
const PricingCardGridItem = ({
  cardData,
  mediumBreakpoint,
  extraSmallBreakpoint,
}: IPricingCardGridItemProps) => {
  /**
   * @function getCardColor
   * @param cardColor the color to be interpreted for card
   * @returns the card color via the swiftcase color
   */
  const getCardColor = (cardColor: string) => {
    switch (cardColor) {
      case "grey":
        return colors.swiftcaseMidGrey;
      case "pink":
        return colors.swiftcaseWebsitePink;
      case "blue":
        return colors.swiftcaseWebsiteBlue;
      default:
        break;
    }
  };
  console.log(cardData);
  /**
   * @function generateListItems
   * @returns the list item components for the list
   */
  const generateListItems = (listItems: Array<string>) => {
    return listItems.map((listItem: string, index: number) => {
      return (
        <GenerateIconListItems
          key={index}
          listItem={listItem}
          index={index}
          icon={
            <CheckCircleOutlined sx={{ color: colors.swiftcaseWebsiteBlue }} />
          }
        />
      );
    });
  };

  return (
    <Grid
      item
      md={mediumBreakpoint ?? 4}
      xs={extraSmallBreakpoint ?? 12}
      sx={{ marginY: { md: "1rem", xs: "2rem" } }}
      className="pricing-card__grid-item"
    >
      <Card
        sx={{ maxWidth: "40rem", marginX: "auto", position: "relative" }}
        className="pricing-card__card"
      >
        {cardData.title === "Professional Edition" && (
          <Star
            sx={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              fontSize: "3rem",
              color: colors.swiftcaseGold,
            }}
          />
        )}
        <CardHeader
          className="pricing-card__card-header"
          title={cardData.title}
          titleTypographyProps={{
            align: "center",
            color: colors.swiftcaseWhite,
            variant: "h4",
          }}
          subheader={cardData.subtitle}
          subheaderTypographyProps={{
            variant: "h6",
            align: "center",
            color: colors.swiftcaseWhite,
          }}
          sx={{ backgroundColor: getCardColor(cardData.color) || "inherit" }}
        />
        <CardContent className="pricing-card__card-content">
          <Typography
            className="pricing-card__card-price"
            variant="h2"
            fontSize="5rem"
            textAlign="center"
            fontWeight={theme.fontWeights.swiftcaseExtraBold}
            color={colors.swiftcaseDarkBlue}
          >
            {cardData.price}
          </Typography>
          <Typography
            className="pricing-card__card-sub-price"
            variant="body1"
            textAlign="center"
            color={colors.swiftcaseMidGrey}
            sx={{ marginY: "1rem" }}
          >
            {cardData.priceSubtitle}
          </Typography>
          <List
            className="pricing-card__card-list"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {generateListItems(cardData.listItems)}
          </List>
        </CardContent>
        <CardActions
          sx={{ justifyContent: "center", marginY: "2rem" }}
          className="pricing-card__card-actions"
        >
          <Button
            className="pricing-card__card-button"
            variant="contained"
            size="small"
            href={cardData.buttonLink}
            disabled={
              cardData.buttonText === "Coming Soon" ||
              cardData.buttonLink === ""
                ? true
                : false
            }
            sx={{
              height: "100%",
              backgroundColor: getCardColor(cardData.color),
              ":hover": {
                backgroundColor: getCardColor(cardData.color),
                color: colors.swiftcaseWhite,
                textDecoration: "underline",
              },
            }}
          >
            {cardData.buttonText}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default PricingCardGridItem;
