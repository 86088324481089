import React from "react";
import { Button } from "@mui/material";
// Constants
import { urlRoutes } from "../../../exports/constants.exports";
// Assets
import { colors } from "../../../exports/assets.exports";

/**
 *
 * @returns Button component with text of "Contact Us" and href to Contact page
 */
const ContactUsButton = () => {
  return (
    <Button
      variant="contained"
      size="large"
      href={urlRoutes.CONTACT}
      className="contact-us-button"
      sx={{
        height: "100%",
        backgroundColor: colors.swiftcaseGreen,
        ":hover": {
          backgroundColor: colors.swiftcaseGreen,
          color: colors.swiftcaseWhite,
          textDecoration: "underline",
        },
      }}
    >
      Contact Us
    </Button>
  );
};

export default ContactUsButton;
