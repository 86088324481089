// External Imports
import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
// Material UI
import { Paper } from "@mui/material";
// Config
import { colors } from "../../../exports/assets.exports";
import { IPaddedImageProps } from "../../../exports/types.exports";

/**
 * @function PaddedImage
 * @param image
 * @returns the padded image component
 */
const PaddedImage = ({
  image,
  maxWidth,
  backgroundColor,
  noBorder,
  noShadow,
  eagerLoading = false,
  invertImage,
  sxProps,
  useZoom = false,
}: IPaddedImageProps) => {
  return (
    <Paper
      className="padded-image__wrapper"
      sx={{
        ...sxProps,
        padding: "1rem",
        backgroundColor: backgroundColor ?? colors.swiftcaseWhite,
        maxWidth: maxWidth,
        border: noBorder ?? "",
        boxShadow: noShadow ?? "",
      }}
    >
      {useZoom ? (
        <Zoom>
          <img
            className="padded-image__image"
            src={image}
            alt="Help To Grow"
            loading={eagerLoading ? "eager" : "lazy"}
            style={{
              padding: 0,
              filter: invertImage ? "invert(1)" : "none",
              verticalAlign: "middle",
              width: "100%",
              height: "100%",
            }}
          />
        </Zoom>
      ) : (
        <img
          className="padded-image__image"
          src={image}
          alt="Help To Grow"
          loading={eagerLoading ? "eager" : "lazy"}
          style={{
            padding: 0,
            filter: invertImage ? "invert(1)" : "none",
            verticalAlign: "middle",
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </Paper>
  );
};

export default PaddedImage;
