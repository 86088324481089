// External
import React, { useState, SyntheticEvent } from "react";
// Material UI
import { Snackbar, Alert } from "@mui/material";

/**
 * @function SnackbarFeedback component
 * @returns the snackbar and alert message for the form submissions
 */
const SnackbarFeedback = ({
  alertType,
  alertMessage,
}: {
  alertType: "success" | "error" | "info";
  alertMessage: string;
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ zIndex: 1000 }}
      className="form-submission-feedback"
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={alertType}
        sx={{
          width: "100%",
          marginTop: "10rem",
          zIndex: 1000,
          maxWidth: { lg: "30rem", md: "25rem", xs: "initial" },
        }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarFeedback;
